/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   reglement of transaction
 * @component TransactionsReglement
 */
import React from "react";
import { useLocation } from "react-router-dom";
import { useGetTransactionsReglementQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";

const TransactionsReglement = () => {
	// hook location
	const { hash } = useLocation();
	const id_transaction = hash.substring(1);

	// get reglement by transaction request
	const {
		data: reglements,
		isLoading,
		isSuccess,
	} = useGetTransactionsReglementQuery({ id_transaction });

	const statusClassName = (status) => {
		switch (status) {
			case "Annulé":
				return "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap my-2";
			case "Echoué":
				return "inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800 whitespace-nowrap my-2";
			case "Validé":
				return "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap my-2 ";

			default:
				break;
		}
	};

	return (
		<>
			{/* loader component  */}
			{isLoading && (
				<div className="flex justify-center text-center">
					<Spinner className="h-10 w-10 text-primary-700  " />
				</div>
			)}
			{isSuccess &&
				(reglements?.data?.length === 0 ? (
					<div className="flex justify-center text-2xl font-medium text-gray-400 my-4">
						{" "}
						aucun réglement effectué pour cette transaction.
					</div>
				) : (
					<div className="mt-10 flex flex-col">
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
													Réglement ID
												</th>
												<th
													scope="col"
													className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
													Transaction ID
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Operateur
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Montant (XAF)
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Date
												</th>
												<th
													scope="col"
													className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
													Status
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{reglements?.data?.map(
												(transaction) => (
													<tr key={transaction.id}>
														<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-700 sm:pl-6">
															{transaction.id}
														</td>
														<td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-700 sm:pl-6">
															{id_transaction}
														</td>
														<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
															{
																transaction.operateur
															}
														</td>
														<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-700">
															{
																transaction.montant
															}
														</td>
														<td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
															{transaction.date}
														</td>
														<td
															className={statusClassName(
																transaction.statut
															)}>
															{transaction.statut}
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				))}
		</>
	);
};

export default TransactionsReglement;
