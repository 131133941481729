/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component SelectMenu
 * @description menu de selection du type champs de formulaire à generer
 * @props  idform, formsEntities
 */

import { Fragment, useCallback } from "react";
import { Menu, Transition } from "@headlessui/react";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import {
	Bars2Icon,
	Bars4Icon,
	MdRadioButtonChecked,
	MdCheckBox,
	ChevronDownIcon,
} from "../svg";
import { setQuestionForm } from "../../features/form/form.slice";
import { classNames } from "../../styles";

const SelectMenu = ({ idform, formsEntities }) => {
	const dispatch = useDispatch();

	// listes des types des champs de formulaire
	const questionMenu = [
		{
			id: nanoid(),
			children: [
				{
					id: 1,
					icon: Bars2Icon,
					title: "Réponse courte",
					type: "text",
				},
				{
					id: 2,
					icon: Bars4Icon,
					title: "Paragraphe",
					type: "textArea",
				},
			],
		},
		{
			id: nanoid(),
			children: [
				{
					id: 3,
					icon: MdRadioButtonChecked,
					title: "Case à cocher",
					type: "radio",
				},
				{
					id: 4,
					icon: MdCheckBox,
					title: "Choix multiple",
					type: "checkbox",
				},
			],
		},
	];

	// type par defaut
	const defautType = formsEntities[idform]?.type;

	const iconType = (type) => {
		switch (type) {
			case "text":
				return (
					<>
						<Bars2Icon
							className="mr-3 sm:h-6 h-3 sm:w-6 w-3 "
							aria-hidden="true"
						/>
						<span>Réponse courte</span>
					</>
				);
			case "textArea":
				return (
					<>
						<Bars4Icon
							className="mr-3 sm:h-6 h-3 sm:w-6 w-3 "
							aria-hidden="true"
						/>
						<span>Paragraphe</span>
					</>
				);
			case "radio":
				return (
					<>
						<MdRadioButtonChecked
							className="mr-3 sm:h-6 h-3 sm:w-6 w-3 "
							aria-hidden="true"
						/>
						<span>Case à cocher</span>
					</>
				);
			case "checkbox":
				return (
					<>
						<MdCheckBox
							className="mr-3 sm:h-6 h-3 sm:w-6 w-3 "
							aria-hidden="true"
						/>
						<span>Choix multiple</span>
					</>
				);

			default:
				break;
		}
	};

	// genere un chapms de formulaire par le type selectionner
	const handleChange = useCallback(
		(value) => {
			dispatch(
				setQuestionForm({
					type: "TYPE",
					typeInput: value.type,
					id: idform,
				})
			);
		},
		[idform, dispatch]
	);

	return (
		<Menu
			as="div"
			className="relative w-full inline-block text-left">
			<div>
				{/* button menu */}
				<Menu.Button className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2  md:py-4 text-sm sm:text-md font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
					<div
						className={classNames(
							"flex items-center cursor-pointer "
						)}>
						{iconType(defautType)}
					</div>
					<ChevronDownIcon
						className="-mr-1 ml-2 sm:h-5 h-4 sm:w-5 w-4"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>

			{/* menu  */}
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95">
				<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					{questionMenu.map((group, index) => {
						return (
							<div
								className="py-1"
								key={index}>
								{group.children.map((item, indexItem) => {
									return (
										<Menu.Item key={indexItem}>
											{({ active }) => (
												<div
													onClick={() =>
														handleChange(item)
													}
													className={classNames(
														active
															? "bg-gray-100 text-gray-900"
															: "text-gray-700",
														"group flex items-center px-4 py-4 text-sm sm:text-md cursor-pointer"
													)}>
													<item.icon
														className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
														aria-hidden="true"
													/>
													{item.title}
												</div>
											)}
										</Menu.Item>
									);
								})}
							</div>
						);
					})}
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default SelectMenu;
