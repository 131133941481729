/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description maintenance page
 * @component  Construction
 */

import React from "react";
import { FaCogs } from "react-icons/fa";

const Construction = () => {
	return (
		<div className="flex flex-col justify-center items-center gap-y-8">
			<FaCogs className="text-gray-700 w-72 h-72 " />
			<p className="text-secondary-600 text-3xl font-semibold">
				Page en construction
			</p>
		</div>
	);
};

export default Construction;
