/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description contrat  step for insurance souscription
 * @component ContratStep
 */
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import Button from "../../../../../UI/button/Button";
import Input from "../../../../../UI/form/Input";

import axiosInstance from "../../../../../apis/inchAssur";

import ModalWithoutAction from "../../../../feedback/ModalWithoutAction";
import { useValideCodeContratMutation } from "../../../../../features/api/apiSlice";
import { getToken } from "../../../../../features/user/auth.slice";

const ContratStep = ({ documents, setStep, steps }) => {
	console.log("contrat", documents)
	const codeRef = useRef();
	// token
	const token = useSelector(getToken);

	// code state
	const [code, setCode] = useState(false);

	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);

	// code error state
	const [errorCode, setErrorCode] = useState({
		error: undefined,
		isError: false,
	});
	// destructuration of error code
	const { error, isError } = errorCode;

	// vilidate code request
	const [valideCode, { isLoading }] = useValideCodeContratMutation();

	// get code
	const getCode = () => {
		if (isError) {
			setErrorCode((prevState) => {
				return {
					...prevState,
					error: "",
					isError: false,
				};
			});
		}
		setLoading(true);
		axiosInstance
			.get("product/signature", {
				headers: {
					Authorization: "Bearer " + token,
					connection: "keep-alive",
				},
			})
			.then((res) => {
				setCode(true);
				setOpen(true);
			})
			.catch()
			.then(() => setLoading(false));
	};

	// code handle change
	const codeHandleChange = (e) => {
		if (e.target.value.trim()) {
			setErrorCode((prevState) => {
				return {
					...prevState,
					error: undefined,
					isError: false,
				};
			});
		}
	};

	// send code
	const sendCode = (e) => {
		e.preventDefault();
		const code = codeRef.current.value;
		valideCode(code)
			.unwrap()
			.then((res) => {
				setStep(steps[4], 4);
			})
			.catch((err) => {
				setErrorCode((prevState) => {
					return {
						...prevState,
						error: err?.data?.message,
						isError: true,
					};
				});
			});
	};

	// download file
	const downloadHandle = (e, uri, nom) => {
		e.preventDefault();

		const link = document.createElement("a");
		link.href = uri;
		link.target = "_blank";
		link.setAttribute("download", `${nom}`);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();

		// Clean up and remove the link
		link.parentNode.removeChild(link);
	};

	return (
		<>
			<div className="px-4 sm:px-6 lg:px-8">
			<div className="my-4 flex items-center justify-between">
					<Button
						children="Etape précédente"
						type="button"
						onClick={() =>setStep(steps[2], 2)}
					/>
			</div>
				<div className="sm:flex sm:items-center">				
					<div className="sm:flex-auto">
						<h1  le contrat className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
							Validez et signez
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Liste des documents à télécharger .
						</p>
					</div>
				</div>

				<div className="mt-8 flex flex-col">
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg ">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-gray-50">
										<tr className=" ">
											<th
												scope="col"
												className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">
												Titre
											</th>
											<th
												scope="col"
												className="relative py-3.5 pl-3 pr-4 sm:pr-6 only:border-0">
												<span className="sr-only">
													Télécharger
												</span>
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{documents?.map((document) => (
											<tr
												key={document.id}
												className="">
												<td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
													{document.nom}
												</td>
												<td className="whitespace-nowrap p-4 text-sm text-gray-500">
													<Button
														type="button"
														download
														onClick={(e) =>
															downloadHandle(
																e,
																document.uri,
																document.nom
															)
														}>
														Télécharger
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className=" flex justify-between mt-4 ">
					
					<div className="flex items-center gap-x-8">
						{code ? (
							<form
								className=" justify-self-end flex items-start gap-x-4"
								onSubmit={sendCode}
								name="codevalidate">
								<Input
									placeholder={"entrer le code pour signer "}
									onChange={codeHandleChange}
									inputError={isError}
									ariaInputError="code"
									errorText={error}
									input={{ id: "code", type: "text" }}
									ref={codeRef}
									name={"code"}
									disabled={false}
									required={true}
								/>
								{isError ? (
									<Button
										children="Generer le code"
										type="button"
										loading={loading}
										onClick={getCode}
										id="codevalidate"
									/>
								) : (
									<Button
										type="submit"
										loading={loading || isLoading}
										children="Signer"
									/>
								)}
							</form>
						) : (
							<Button
								children="Signer le contrat"
								type="button"
								loading={loading}
								onClick={getCode}
								id="codevalidate"
							/>
						)}
					</div>
				</div>
			</div>

			{/* modal */}
			{open && (
				<ModalWithoutAction
					openFeedBack={open}
					description="Un mail contenant un code de signature vous a été envoyé."
					title="Code de signature"
					setOpen={setOpen}
				/>
			)}
		</>
	);
};

export default ContratStep;
