
/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance inch souscribe by user page
 * @component ListAssuranceByIdCategorie
 */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";


import { useGetAssurancesByCategorieQuery } from "../../../features/api/apiSlice";
import { InputText } from 'primereact/inputtext';

import AssuranceCard from "../../../components/layouts/main/assurances/client/AssuranceCard";
import Pagination from "../../../UI/pagination";
import Spinner from "../../../UI/spinner/Spinner";
import Button from "../../../UI/button/Button";
import usePagination from "../../../hooks/usePagination";

const ListAssuranceByIdCategorie = () => {
	const navigate = useNavigate();
	const params = useParams();

	// insurances state
	const [assurances, setassurance] = useState([]);
	// search stata
	const [queryAssurance, setQueryAssurance] = useState("");
	
	// get insurance by category request
	const { data, isSuccess, isLoading } = useGetAssurancesByCategorieQuery(params?.id);

	let content;

	// set data insurances
	useEffect(() => {
		if (isSuccess) {
			setassurance(
				data?.data?.map((item) => {
					return {
						id: item.id_produit,
						code: item.code,
						description: item.description,
						duree: item.duree,
						images: item.images,
						titre: item.nom,
						statut: item.statut,
						prix: parseInt(item.prix),
						fournisseur: item.fournisseur,
						services: item.listeServices,
						pieces_a_joindre: item.pieces_a_joindre,
						type_contrat: item.type_contrat,
						categorie: item.categorie,
						documents: item.documents,
					};
				})
			);
		}

		return () => { };
	}, [isSuccess, data]);

	// transaction  per page
	const itemsPerPage = 12;

	

	const filteredAssurance =
	queryAssurance === ""
	? assurances
	: assurances.filter((assurance) => {
		return assurance.titre
			.toLowerCase()
			.includes(queryAssurance.toLowerCase());
	});

	// pagination hook
	const {
		next,
		prev,
		jump,
		currentData,
		currentPage,
		maxPage,
		dataLength,
		beginItem,
		endItem,
	} = usePagination(filteredAssurance, itemsPerPage);



	if (isLoading) {
		content = (
			<div className="flex flex-1 justify-center">
				<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
			</div>
		);
	} else if (isSuccess) {
		content = (
			<div className="bg-white mt-8">
				<div className="flex justify-between px-6 mb-4 ">


					<Button
						children="Etape précédente"
						type="button"
						onClick={() => navigate(`/assurances/categorie`)}
					/>

				</div>
				<div className="mx-auto max-w-2xl  px-4  sm:px-6 lg:max-w-7xl">

					<div className="flex justify-between">


						<h1 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
							Sélectionner une assurance{" "}
						</h1>
						<div className="flex justify-content-end">
							<span className="p-input-icon-left">
								<InputText placeholder="Rechercher ..."  onChange={(event) =>setQueryAssurance(event.target.value)}/>
							</span>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8 mb-2">
						{currentData.map((item, index) => {
							return (
								<AssuranceCard
									key={index}
									duree={item.duree}
									description={item.description}
									titre={item.titre}
									images={item.images[0]?.uri}
									categorie={item.categorie.nom.toLowerCase()}
									id={item.id}
									code={item.code}
									prix={item.prix}
								/>
							);
						})}
					</div>

					{/* pagination  */}
					{filteredAssurance?.length > itemsPerPage && (
						<Pagination
							next={next}
							prev={prev}
							jump={jump}
							currentData={currentData}
							currentPage={currentPage}
							maxPage={maxPage}
							dataLength={dataLength}
							itemsPerPage={itemsPerPage}
							beginItem={beginItem}
							endItem={endItem}
						/>
					)}
				</div>
			</div>
		);
	} else {
		return;
	}

	return content;
};

export default ListAssuranceByIdCategorie;
