/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance product card
 * @component ProduitCard
 */

import Button from "../../../../../UI/button/Button";
import { BsHourglassSplit } from "../../../../../UI/svg/index";

import { formatPrix } from "../../../../../lib/Assurance";

import { Link } from "react-router-dom";

const ProduitCard = (props) => {
	// props
	const { titre, code, images, categorie, prix, duree, des, restants } =
		props;

	const currency = formatPrix(prix);

	return (
		<>
			<div className=" group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
				<div className="aspect-w-2 aspect-h-2 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-40">
					<Link to={`../../${code}`}>
						<img
							src={images}
							alt="images produits"
							className="h-full w-full object-cover object-center sm:h-full sm:w-full"
						/>
					</Link>
				</div>
				<div className="flex flex-1 flex-col space-y-2 p-4">
					<h3 className="text-lg font-medium text-gray-900 flex flex-1 flex-col justify-end">
						<Link to={`//${code}`}>
							<span aria-hidden="true" />
							{titre}
						</Link>
						<small className="text-sm font-medium text-gray-600">
							Catégorie: {categorie}
						</small>
						<small className="text-sm font-medium text-gray-600">
							Code: {code}
						</small>
					</h3>
					<p className="text-sm text-gray-500 break-words">{des}</p>

					<div className="flex flex-1  justify-between items-center">
						<p className="text-sm italic text-gray-500 flex justify-start items-center">
							<BsHourglassSplit className="h-5 w-5 text-green-400" />
							<span className="text-md ml-1 font-medium text-gray-800">
								Durée {duree} Jours
							</span>
						</p>
					</div>
					<div className="flex flex-1  justify-between items-center">
						<p className="text-sm italic text-gray-500 flex justify-start items-center">
							<BsHourglassSplit className="h-5 w-5 text-primary-800" />
							<span className="text-md ml-1 font-medium text-gray-800">
								Expire dans {restants}{" "}
							</span>
						</p>
					</div>
					<p className="text-sm italic text-gray-500 flex justify-start items-center"></p>
					<p className="text-base font-medium text-gray-900">
						Montant: {currency}
					</p>
				</div>
				{restants === "10" && (
					<Link
						to={`${code}`}
						className="my-4 px-4">
						<Button
							children="Renouveller"
							button="primary"
						/>
					</Link>
				)}
			</div>
		</>
	);
};

export default ProduitCard;
