import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../../../../UI/form/Input";
import TextArea from "../../../../../UI/form/TextArea";
import Button from "../../../../../UI/button/Button";
import { useCallback, useEffect, useState } from "react";
import {
    useAddAssuranceCategoryMutation,
    useEditAssuranceCategoryMutation
} from "../../../../../features/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { getModalProps, getModalType, initialModal, setModal } from "../../../../../features/feedBack/modal.slice";
import { Alert } from "../../UserRecharge/Recharge";

function AddCategory() {
    /** State constants *******************************************************/
    const [categoryInfo, setCategoryInfo] = useState({ nom: "caterogy name" });
    const [tag, setTag] = useState("");
    const [tagsList, setTagsList] = useState([]);
    const [ addCategory, { isLoading: loading } ] = useAddAssuranceCategoryMutation();
    const [ editCategory, { isLoading: editloading } ] = useEditAssuranceCategoryMutation();
    /************************************************************************************** */

    /** Alert modal constants *********************/
    const dispatch = useDispatch();
    const modalType = useSelector(getModalType);
    const modalProps = useSelector(getModalProps);
    const navigate = useNavigate();
    const closeAlert = useCallback(() => {
        dispatch(initialModal());
    }, [dispatch]);
    /********************************************* */

    /** Request params and data constants **************/
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const callContext = params.get("usage") || "";
    const editionData = location.state;
    /************************************************* */

    /** Effects section **********************************************************/
    // This effect affect all inputs value after the first render of this component
    useEffect(() => {
        if (callContext === "edit") {
            const newEditionData = {
                nom: editionData?.nom,
                description: editionData?.description,
            }
            setTagsList(editionData?.tag);
            setCategoryInfo(newEditionData);
        }
    }, []);

    // This effect affect close the alert modal after the defined time (in millisecond)
    useEffect(() => {
        if (modalProps?.isOpen) {
            setTimeout(closeAlert, 5000)
        }

        return () => {
            clearTimeout();
        }
    }, [modalProps?.isOpen, closeAlert]);
    /***************************************************************************** */

    /** Component utilities functions section ************************/
    // This function is't use to générate the modal configuration
    function setModalPopUp(text, status) {
        return (
            dispatch(
                setModal({
                    modalType: "user",
                    modalProps: {
                        isOpen: true,
                        status: status,
                        text: text
                    }
                })
            )
        )
    };

    // This function provide the conditional value for inputs depending on the call context
    function inputValueProvider(callContext, value) {
        return callContext === "edit" ? value : undefined;
    };

    // This one its used to set the categoryInfo state depending of data typed by user
    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        setCategoryInfo({
            ...categoryInfo,
            [name]: value
        });
    }

    // This one is used to handle tags changes by user
    function handleTagChange(event) {
        const value = event.target.value;
        setTag(value);
    }

    // This one is used to set tags list state when the "Enter" key is pressed
    function handleEnterKeyPress(event) {
        if (event.key === "Enter" && event.target.value !== "") {
            event.preventDefault();

            setTagsList([ ...tagsList, tag ]);
            setTag("");
            event.target.value = "";
        }
    }

    // This one is used to remove a tags to the tags list state when user remove one of
    function handleRemoveTag(deletionIndex) {
        const newTags = tagsList.filter((tag, index) => index !== deletionIndex)
        setTagsList(newTags);
    }

    /** This function submit the adding ou update form,
     *  after checking for the presence of all required value,
     * preparing tags list by stringnifying them,
     * setting up status depending on the call context of the componenet
     */
    async function handleSubmit(event) {
        event.preventDefault();
        const statut = callContext === "edit" ? editionData.statut : "Active";
        let tags;

        // Ensuring component will not crash because of stringifing error
        try {
            tags = JSON.stringify(tagsList);
        } catch (error) {
            tags = JSON.stringify(["misStingified"]);
            console.log("stingifying error :", error);
        }
        
        if (!categoryInfo?.nom || categoryInfo?.nom.length === 0) {
            return (
                setModalPopUp("Veuillez remplir le champ 'Nom' svp !", "failed")
            )
        }

        if (!categoryInfo?.description || categoryInfo?.description.length === 0) {
            return (
                setModalPopUp("Veuillez remplir le champ 'Description' svp !", "failed")
            )
        }

        const formData = new FormData();

        formData.append("nom", categoryInfo?.nom);
        formData.append("description", categoryInfo?.description);
        formData.append("statut", statut);
        formData.append("tag", tags);

        const sentData = callContext === "edit"
        ? { id: editionData.id_categorieProduit, data: formData }
        : {};

        try {
            const response = callContext === "edit"
            ? await editCategory(sentData).unwrap()
            : await addCategory(formData).unwrap();

            const message = typeof response.message === "string"
            ? response.message
            : "réponse incohérente";

            setModalPopUp(message, "success");
            navigate("/parametres/categories-assurance");
        } catch (error) {
            let message;

            if (!error?.data?.message) {
                message = "Une erreur est survenue !";
            } else if (typeof error?.data?.message !== "string") {
                message = "réponse incohérente";
            } else {
                message = error?.data?.message;
            };
            
            setModalPopUp(message, "failed");
        }
    }
    /*********************************************************************************** */

    return (
        <>
            {/* alert componenet  */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4 fixed top-5 z-50 w-4/5 inset-x-0 mx-auto">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}
            
            <div className="ml-[30px] mt-[30px] mr-[30px] divide-y divide-gray-200">
                <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        { callContext === "edit" ? `Modifier la catégorie ${editionData.nom}` : "Ajouter une catégorie de produit" }
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Bien vouloir remplir ce formulaire pour créer une catégorie de produit.
                    </p>
                </div>

                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-4 mt-12"
                >
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5 mt-4 sm:mt-0">
                        <label
                            htmlFor="nom"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Nom de catégorie :
                        </label>

                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="sm:max-w-xs">
                                <Input
                                    input={{
                                        id: "nom",
                                        type: "text",
                                        maxLength: "80"
                                    }}
                                    name="nom"
                                    placeholder="Entrez un nom pour la catégorie !"
                                    disabled={false}
                                    required={true}
                                    onChange={handleChange}
                                    value={inputValueProvider(callContext, categoryInfo?.nom)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                        <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Description :
                        </label>

                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="sm:max-w-xs">
                                <TextArea
                                    id="description"
                                    name="description"
                                    placeholder="Entrez une description pour la catégorie !"
                                    rows={6}
                                    disabled={false}
                                    required={true}
                                    onChange={handleChange}
                                    value={inputValueProvider(callContext, categoryInfo?.description)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                        <label
                            htmlFor="tags"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Tags :
                        </label>

                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="sm:max-w-xs">
                                <div className="flex gap-2 mx-auto w-50 px-1 flex-wrap">
                                    {tagsList.length > 0
                                    ? (
                                        tagsList.map((tag, index) => <div
                                            key={`${index}-${tag}`}
                                            className="flex gap-2 border border-gray-500 rounded-md bg-gray-200 px-2 py-1 text-sm font-sm text-gray-700"
                                        >
                                            {tag}
                                            <div
                                                className="bg-red-400 border border-red-600 text-white font-bold py-0 px-1 rounded-sm cursor-pointer"
                                                onClick={() => handleRemoveTag(index)}
                                            >x</div>
                                        </div>)
                                    )
                                    : (<div className="text-sm font-sm text-gray-700">Aucun tag ajouté...</div>)}
                                </div>

                                <Input
                                    input={{
                                        id: "tags",
                                        type: "text",
                                        maxLength: "80"
                                    }}

                                    name="tags"
                                    placeholder="Entrez un tag pour cette catégorie !"
                                    disabled={false}
                                    required={false}
                                    onChange={handleTagChange}
                                    onKeyDown={handleEnterKeyPress}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="pt-5 bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-end">
                        <Button
                            children="Ajouter la catégorie"
                            button="primary"
                            type="submit"
                            className="max-w-max"
                            loading={loading || editloading}
                        />
                    </div>
                </form>
            </div>
        </>
    )
};

export default AddCategory;
