/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description change profil
 * @component ChangeProfil
 */
import React from "react";
import { SelectProfile } from "../../../UI/form/Select";
import {
	useSetUserDefaultProfilMutation,
	apiSlice,
} from "../../../features/api/apiSlice";
import { getUser } from "../../../features/user/user.slice";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../../features/user/auth.slice";
import { useNavigate } from "react-router-dom";

const ChangeProfil = ({
	nom,
	prenom,
	profils,
	defaultProfil,
	setShow,
	userProfile,
}) => {
	// all profils user
	const mesProfiles = profils?.map((item) => {
		return {
			id: item.id,
			name: item.value,
		};
	});

	// get photo profil user
	const { photo_profil } = useSelector(getUser);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	// useHook for set default profil
	const [setDefaultProfil] = useSetUserDefaultProfilMutation();

	// profil handle change
	const profileHandleChange = async (e) => {
		const profil = e.target.value;

		try {
			await setDefaultProfil({ niveau: profil })
				.unwrap()
				.then((data) => {
					// reset all global api state
					dispatch(apiSlice.util.resetApiState());

					dispatch(logIn(data?.token));

					setShow(true);
					navigate("/dashboard", { replace: true });
				});
		} catch (err) {
			console.error("Failed to save the post: ", err);
		}
	};

	return (
		<div className="mt-5  px-2  ">
			<span className="flex min-w-0 items-center justify-between space-x-3 pl-2 pr-1 py-2 text-left rounded-md hover:bg-secondary-600">
				<img
					className="h-10 w-10 flex-shrink-0 object-cover shadow-sm border border-secondary-700 rounded-full bg-gray-300"
					src={photo_profil || userProfile}
					alt=""
				/>
				<span className="flex min-w-0 flex-1 flex-col text-white">
					<span className="truncate text-md font-medium ">{`${nom} ${prenom}`}</span>
					{mesProfiles?.length > 1 ? (
						<SelectProfile
							valueOption={mesProfiles}
							name="profil"
							onChange={profileHandleChange}
							selected="Choisir le profil"
							defaultValue={defaultProfil?.id}
						/>
					) : (
						<span className="truncate text-sm text-gray-100">
							{defaultProfil?.value}
						</span>
					)}
				</span>
			</span>
		</div>
	);
};

export default ChangeProfil;
