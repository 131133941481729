/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product services and pieces
 * @component Document
 */
import React from "react";
import SelectTo from "./SelectTo";
import { MinusIcon } from "../../../../../UI/svg";
import Button from "../../../../../UI/button/Button";
import SelectPieces from "./SelectPieces";

const Services = ({ SetDataAssurance, dataAssurance, setSelectedIndex }) => {
	const { services, pieces } = dataAssurance;

	// add services
	const selectHandleChange = (value) => {
		let newTable;
		let newSerices;
		if (services.length === 0) {
			newTable = services;
			newSerices = newTable.concat(value);
			SetDataAssurance((prevState) => {
				return {
					...prevState,
					services: newSerices,
				};
			});
		} else {
			const isValue = services.filter((item) => item.id === value.id);
			if (isValue.length === 0) {
				newTable = services;
				newSerices = newTable.concat(value);
				SetDataAssurance((prevState) => {
					return {
						...prevState,
						services: newSerices,
					};
				});
			} else {
				if (isValue[0].id === value.id) {
					return;
				} else {
					newTable = services;
					newSerices = newTable.concat(value);
					SetDataAssurance((prevState) => {
						return {
							...prevState,
							services: newSerices,
						};
					});
				}
			}
		}
	};

	// add piece
	const addPieces = (value) => {
		let newTable;
		let newPieces;
		if (pieces.length === 0) {
			newTable = pieces;
			newPieces = newTable.concat(value);
			SetDataAssurance((prevState) => {
				return {
					...prevState,
					pieces: newPieces,
				};
			});
		} else {
			const isValue = pieces.filter((item) => item.id === value.id);
			if (isValue.length === 0) {
				newTable = pieces;
				newPieces = newTable.concat(value);
				SetDataAssurance((prevState) => {
					return {
						...prevState,
						pieces: newPieces,
					};
				});
			} else {
				if (isValue[0].id === value.id) {
					return;
				} else {
					newTable = pieces;
					newPieces = newTable.concat(value);
					SetDataAssurance((prevState) => {
						return {
							...prevState,
							pieces: newPieces,
						};
					});
				}
			}
		}
	};

	// remove service and piece
	const removeItem = (index, url, type) => {
		switch (type) {
			case "services":
				const newTableServices = services.filter(
					(item) => item !== url
				);
				SetDataAssurance((prevState) => {
					return {
						...prevState,
						services: newTableServices,
					};
				});
				break;

			case "pieces":
				const newTablePieces = pieces.filter((item) => item !== url);
				SetDataAssurance((prevState) => {
					return {
						...prevState,
						pieces: newTablePieces,
					};
				});
				break;

			default:
				break;
		}
	};

	return (
		<div className="sm:px-8 px-4 grid grid-cols-5 gap-8">
			<div className="col-span-5 sm:col-span-2">
				<div className="flex flex-col gap-y-8 rounded-md">
					{/* guaranty  */}
					<div>
						<label
							className=" block text-sm font-medium text-gray-700 mb-1"
							id="garantie">
							Garantie de l'assurance
						</label>
						<SelectTo
							onChange={selectHandleChange}
							ariaLabel="garantie"
						/>
					</div>

					{/* list guaranty  */}
					<div className="bg-gray-50 p-4">
						<h3 className="text-gray-700">
							Listes des garanties du produit d'assurance
						</h3>
						<ul>
							{dataAssurance?.services?.map((item, index) => {
								return (
									<li
										className="flex items-center justify-between"
										key={index}>
										<span>{item.value}</span>{" "}
										<MinusIcon
											className="w-4 h-4 cursor-pointer"
											onClick={() =>
												removeItem(
													index,
													item,
													"services"
												)
											}
										/>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>

			{/* pieces  */}
			<div className="col-span-5 sm:col-span-2">
				<div className="flex flex-col gap-y-8 rounded-md">
					<div>
						<label
							className=" block text-sm font-medium text-gray-700 mb-1"
							id="garantie">
							Pieces à joindre par le client
						</label>
						<SelectPieces
							onChange={addPieces}
							ariaLabel="Pieces"
						/>
					</div>
					<div className="bg-gray-50 p-4 ">
						<h3 className="text-gray-700">
							Listes des pieces à joindre par le client
						</h3>
						<ul>
							{dataAssurance?.pieces?.map((item, index) => {
								return (
									<li
										className="flex items-center justify-between"
										key={index}>
										<span>{item.value}</span>{" "}
										<MinusIcon
											className="w-4 h-4 cursor-pointer"
											onClick={() =>
												removeItem(
													index,
													item,
													"pieces"
												)
											}
										/>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>

			<div className="col-span-5 sm:col-span-4 gap-x-4 flex justify-end items-center">
				<Button
					type="button"
					children="Précedent"
					button="default"
					onClick={() => setSelectedIndex(0)}
				/>
				<Button
					type="button"
					children="Suivant"
					button="default"
					onClick={() => setSelectedIndex(2)}
				/>
			</div>
		</div>
	);
};

export default Services;
