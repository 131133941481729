/**
 * @author roche ngaleu
 * @email ibikivan@gmail.com
 * @description show assurance categories
 * @component CategoriesAssurances
 */

import React, { useCallback, useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getModalType,
    getModalProps,
    initialModal,
    setModal
} from "../../../../../features/feedBack/modal.slice";
import { Alert } from "../../UserRecharge/Recharge";
import { Link } from "react-router-dom";
import {
    useGetAllAssurancesCategorieQuery,
    useGetDisactivateAssuranceCategoryMutation,
    useGetActivateAssuranceCategoryMutation,
    useEditAssuranceCategoryMutation
} from "../../../../../features/api/apiSlice";
import Spinner from "../../../../../UI/spinner/Spinner";
import SpinnerEllipsi from "../../../../../UI/spinner/SpinnerEllipsi";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "../../../../../UI/svg";
import {
    EyeIcon,
    PencilIcon,
    LockClosedIcon,
    ArchiveBoxIcon
} from "@heroicons/react/20/solid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

function CategoriesAssurances() {
    /** Local state declarations ****************************************************************************************/
    // Filter state
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        statut: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        tag: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const [currentCategory, setCurrentCategory] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    /** ********************************************************************************************************************/

    /** Get catégories request *****************************************************************/
    const { data: categories, isLoading, isSuccess } = useGetAllAssurancesCategorieQuery();
    /** ******************************************************************************************************/

    /** Category actiavtion/disactivation queries *****************************************************************/
    const [ desactivateCategory, { isLoading: isDesactivating } ] = useGetDisactivateAssuranceCategoryMutation();
    const [ activateCategory, { isLoading: isActivating } ] = useGetActivateAssuranceCategoryMutation();
    const [ editCategory, { isLoading: editloading } ] = useEditAssuranceCategoryMutation();
    /** ************************************************************************************************************/

    /** Modal context content *********************************/
    const modalType = useSelector(getModalType);
    const modalProps = useSelector(getModalProps);
    const dispatch = useDispatch();
    /** *****************************************************/

    /** All categories constants ***********************************************************************************/
    const data = categories?.data;
    const closeAlert = useCallback(() => {
        dispatch(initialModal());
    }, [dispatch]);
    /** ******************************************************************************************************/

    /** This effect will close the alert modal afetr setTimeout defined time if it's isn't closed by the user ***/
    useEffect(() => {
        if(modalProps?.isOpen) {
            setTimeout(closeAlert, 5000);
        }

        return () => {
            clearTimeout();
        }
    }, [modalProps?.isOpen, closeAlert]);
    /** *********************************************************************************************************/

    /** Data table utilities functions *********************************************************************/
    // used to change the statut column color
    const getSeverity = (statut) => {
        switch (statut) {
            case 'Inactive':
                return 'warning';
                
            case 'Active':
                return 'success';

            case 'Supprimee':
                return 'danger';

            default :
                return 'info';
        }
    };

    // Used to when sending data to the edit component to prepare the to thi component needs
    function provideEditData(data) {
        let editionData;

        if (data) {
            let tags;
            try {
                tags = (data?.tag !== "undefined" && typeof data?.tag === "string" && data?.tag.length > 0) ? JSON.parse(data.tag) : [];
            } catch (error) {
                console.log("Parsing error :", error);
                tags = ["misParsed"];
            }

            editionData = {
                id_categorieProduit: data?.id_categorieProduit,
                nom: data?.nom,
                description: data?.description,
                statut: data?.statut,
                tag: tags,
                dateModification: data?.dateModification
            };
        };
        return editionData;
    };

    // Used to replace data's array with a properly tags formated data type as array
    function formatData(data) {
        return data.map((item) => {
            return provideEditData(item);
        })
    };

    // This function is't use to générate the modal configuration
    function setModalPopUp(text, status) {
        return (
            dispatch(
                setModal({
                    modalType: "user",
                    modalProps: {
                        isOpen: true,
                        status: status,
                        text: text
                    }
                })
            )
        )
    };

    // Used to disactivate an active catégory
    async function handleCategoryLock(data) {
        setCurrentCategory(data.id_categorieProduit);

        // disactivating the catégory
        try {
            const response = await desactivateCategory(data.id_categorieProduit).unwrap();

            const message = typeof response.message === "string"
            ? response.message
            : "réponse incohérente";

            setModalPopUp(message, "success");
        } catch (error) {
            let message;

            if (!error?.data?.message) {
                message = "Une erreur est survenue !";
            } else if (typeof error?.data?.message !== "string") {
                message = "réponse incohérente";
            } else {
                message = error?.data?.message;
            };

            setModalPopUp(message, "failed");
        };
    };

    // Used to activate an unactive category
    async function handleCategoryUnlock(data) {
        setCurrentCategory(data.id_categorieProduit);

        // activating the catégory
        try {
            const response = await activateCategory(data.id_categorieProduit).unwrap();

            const message = typeof response.message === "string"
            ? response.message
            : "réponse incohérente";

            setModalPopUp(message, "success");
        } catch (error) {
            let message;

            if (!error?.data?.message) {
                message = "Une erreur est survenue !";
            } else if (typeof error?.data?.message !== "string") {
                message = "réponse incohérente";
            } else {
                message = error?.data?.message;
            };

            setModalPopUp(message, "failed");
        };
    };

    // Used to delete a category
    async function handleCategoryDeleted(data) {
        setCurrentCategory(data?.id_categorieProduit);

        const formData = new FormData();
        formData.append("statut", "Supprimee");

        const sentData = { id: data?.id_categorieProduit, data: formData };

        // deleting the catégory
        try {
            const response = await editCategory(sentData).unwrap();

            const message = typeof response.message === "string"
            ? response.message
            : "réponse incohérente";

            setModalPopUp(message, "success");
        } catch (error) {
            let message;

            if (!error?.data?.message) {
                message = "Une erreur est survenue !";
            } else if (typeof error?.data?.message !== "string") {
                message = "réponse incohérente";
            } else {
                message = error?.data?.message;
            };

            setModalPopUp(message, "failed");
        };
    };

    // Used by the data table component to setting up filters
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    /****************************************************************************************** */

    /** Data table redering functions *****************************************************************************/
    // Used to render the data table user row
    const renderHeader = () => {
        return (
            <div className="flex gap-2 justify-between items-center">
                <h4 className="m-0">Catégories</h4>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Rechercher" />
                </span>
            </div>
        );
    };

    // Used to render the tags column appearance
    const tagBodyTemplate = (rowData) => {
        function formatTags(tag) {
            try {
                if (Array.isArray(tag) && tag.length > 0) {
                    return tag.join(' ');
                } else {
                    return 'inconnu';
                }
            } catch (error) {
                return 'Erreur';
            };
        };
        
        return (
            <div className="flex gap-2 items-center">
                <span>{formatTags(rowData.tag)}</span>
            </div>
        );
    };

    // Used to render the statut column appearance
    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.statut} severity={getSeverity(rowData.statut)} />;
    };

    // Used to render the action column appearance
    const actionBodyTemplate = (rowData) => {
        return (
            <td className="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                { (isDesactivating || isActivating || editloading) && currentCategory === rowData.id_categorieProduit
                    ? (
                        <SpinnerEllipsi
                            className="inline-block text-left"
                            classNameEllipsi="h-6 w-6 "
                            classNameSpin="h-7 w-7 text-secondary-500"
                        />
                    )
                    : (
                        <Menu
                            as="div"
                            className="inline-block text-left"
                        >
                            {/* This is the Action Icon */}
                            <Menu.Button
                                className="relative flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                            >
                                <EllipsisHorizontalIcon
                                    className="h-7 w-7"
                                    aria-hidden="true"
                                />
                            </Menu.Button>

                            {/* Starting rendering the Action Menu by appearing in transition */}
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none space-y-1"
                                >
                                    <Menu.Item>
                                        <Link
                                            to="view"
                                            state={rowData}
                                            className="hover:bg-gray-100 text-gray-900 group flex items-center px-4 py-2 text-sm"
                                        >
                                            <EyeIcon
                                                className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500"
                                                aria-hidden="true"
                                            />
                                            Consulter
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link
                                            to="add?usage=edit"
                                            state={rowData}
                                            className="hover:bg-gray-100 text-gray-900 group flex items-center px-4 py-2 text-sm"
                                        >
                                            <PencilIcon
                                                className="mr-3 h-5 w-5 text-blue-400 group-hover:text-blue-500"
                                                aria-hidden="true"
                                            />
                                            Editer
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <span
                                                onClick={() => rowData.statut === "Active" ? handleCategoryLock(rowData) : handleCategoryUnlock(rowData)}
                                                className={ `${active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700"} cursor-pointer group flex items-center px-4 py-2 text-sm`
                                            }
                                            >
                                                <LockClosedIcon
                                                    className={`mr-3 h-5 w-5
                                                        ${rowData.statut === "Active"
                                                        ? "text-yellow-400 group-hover:text-yellow-500"
                                                        : "text-green-400 group-hover:text-green-500"}
                                                    `}
                                                    aria-hidden="true"
                                                />
                                                { rowData.statut === "Active" ? "Désactiver" : "Activer" }
                                            </span>
                                        )}
                                    </Menu.Item>
                                    {rowData.statut !== "Supprimee"
                                    && <Menu.Item>
                                        <span
                                            onClick={() => handleCategoryDeleted(rowData)}
                                            className="hover:bg-gray-100 text-gray-900 group flex items-center px-4 py-2 text-sm"
                                        >
                                            <ArchiveBoxIcon
                                                className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                                                aria-hidden="true"
                                            />
                                            Supprimer
                                        </span>
                                    </Menu.Item>}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    )
                }
            </td>
        );
    };

    // A data table function used to globaly render the header's row with all foctionalities
    const header = renderHeader();
    /*********************************************************************************************************************************** */

    return (
        <>
            {/* alert componenet  */}
			{modalType === "user" && modalProps?.isOpen ? (
				<div className="px-4 fixed top-5 z-50 w-4/5 inset-x-0 mx-auto">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}

            <div className="px-8 sm:px-6 lg:px-8 mt-8">
                {/* header  */}
				<div className="sm:flex sm:items-center mb-6">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-gray-900">
							{ categories ? categories.message : "" }
						</h1>
						<p className="mt-2 text-sm text-gray-700"></p>
					</div>

					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<Link to="add?usage=add">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter une catégorie
							</button>
						</Link>
					</div>
				</div>

                {/* spiner */}
                {isLoading && (
                    <Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
                )}

                {/* Categories Assurances table */}
                {isSuccess && (
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <DataTable value={formatData(data)} paginator header={header} rows={10}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]} dataKey="id_categorieProduit" selectionMode={rowClick ? null : 'checkbox'} selection={selectedCategory} onSelectionChange={(e) => setSelectedCategory(e.value)}
                                filters={filters} filterDisplay="menu" globalFilterFields={['nom', 'tag', 'statut', 'dateModification']}
                                emptyMessage="No category found." currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" style={{ borderWidth: '1px', borderStyle: 'solid' }}
                                rowClassName="cursor-pointer"
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>
                                <Column field="nom" header="Nom" sortable style={{ width: '1%', textTransform: 'capitalize' }} />
                                <Column field="description" header="Description" style={{ width: '2%' }} />
                                <Column field="statut" header="Status" sortable style={{ width: '1%' }} body={statusBodyTemplate} />
                                <Column field="tag" header="Tag" sortable style={{ width: '1%' }} body={tagBodyTemplate} />
                                <Column field="dateModification" header="Modifié le" sortable dataType="date" style={{ width: '1%' }} />
                                <Column headerStyle={{ width: '1%', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
};

export default CategoriesAssurances;
