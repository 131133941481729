/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component SwitchInput
 * @description ce composant affiche et modifie le statut "isRequired" d'un champs de formulaire
 * @props  idform, formsEntities
 */

import { useCallback } from "react";
import { Switch } from "@headlessui/react";
import { useDispatch } from "react-redux";

import { setQuestionForm } from "../../features/form/form.slice";
import { classNames } from "../../styles";

export default function SwitchInput(props) {
	const dispatch = useDispatch();

	// props
	const { idform, formsEntities } = props;

	// statut par defaut
	const enable = formsEntities[idform]?.isRequired;

	// change le statut
	const handleChange = useCallback(
		(value) => {
			dispatch(
				setQuestionForm({
					type: "REQUIRED",
					isRequired: value,
					id: idform,
				})
			);
		},
		[idform, dispatch]
	);

	return (
		<Switch.Group
			as="div"
			className="flex items-center sm:pl-4 sm:mt-0 mt-6">
			<Switch.Label
				as="span"
				className="mr-3">
				<span className="text-md font-medium text-gray-700 hover:text-gray-900 cursor-pointer">
					Obligatoire
				</span>
			</Switch.Label>
			<Switch
				checked={enable}
				onChange={handleChange}
				className="group relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2">
				<span className="sr-only">Obligatoire</span>
				<span
					aria-hidden="true"
					className="pointer-events-none absolute h-full w-full rounded-md bg-white"
				/>
				<span
					aria-hidden="true"
					className={classNames(
						enable ? "bg-secondary-600" : "bg-gray-200",
						"pointer-events-none absolute mx-auto h-3 w-7 rounded-full transition-colors duration-200 ease-in-out"
					)}
				/>
				<span
					aria-hidden="true"
					className={classNames(
						enable ? "translate-x-5" : "translate-x-0",
						"pointer-events-none absolute left-0 inline-block h-4 w-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
					)}
				/>
			</Switch>
		</Switch.Group>
	);
}
