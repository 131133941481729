/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurances
 * @component ListAssurances
 */
import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import select from "../../../../../assets/selected.png"
import clsx from "clsx";
import { useGetAssurancesCategorieQuery } from "../../../../../features/api/apiSlice";

import Spinner from "../../../../../UI/spinner/Spinner";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function ListCategorie() {
	// get insurance category request
	const { data, isSuccess, isLoading } = useGetAssurancesCategorieQuery();

	// selected category state
	const [categoriSelect, setCategoriSelect] = useState();

	// set selected category
	useEffect(() => {
		if (isSuccess) {
			setCategoriSelect(data?.data[0]);
		}

		return () => { };
	}, [setCategoriSelect, isSuccess, data]);

	let content;

	if (isLoading) {
		content = (
			<div className="flex flex-1 flex-col justify-center">
				<Spinner className="h-10 w-10 text-primary-700 " />
			</div>
		);
	} else if (isSuccess) {
		content = (
			<div className="sm:px-4 px-4 sm:mx-4 sm:mt-4 grid grid-cols-6 gap-6">
				<div className="col-span-6 sm:col-span-4 lg:col-span-4">


					{/* radio category group  */}
					<div >
						<RadioGroup
							value={categoriSelect}
							defaultChecked={data?.data[0]}
							defaultValue={data?.data[0]}
							onChange={setCategoriSelect}>
							<RadioGroup.Label
								as="h1"
								className=" text-2xl font-bold  text-primary-800 sm:text-3xl mb-3">
								Selectionner le type d'assurance
							</RadioGroup.Label>

							<div className=" grid  grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-7 mt-10 ">
								{data?.data.map((categorie) => (
									<RadioGroup.Option
										key={categorie.id_categorieProduit}
										value={categorie}
										className={({ checked, active }) =>
											classNames(
												checked
													? "border-transparent"
													: "border-gray-300",
												active
													? "border-primary-500 ring-2 ring-primary-500"
													: "",
												"relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
											)
										}>
										{({ checked, active }) => (
											<>
												<span className="flex flex-1">
													<span className="flex flex-col">
														<RadioGroup.Label
															as="span"
															className="block text-md font-medium text-gray-900">
															{categorie.nom}
														</RadioGroup.Label>
													</span>
												</span>
												<CheckCircleIcon
													className={classNames(
														!checked ? "invisible" : "",
														"h-5 w-5 text-primary-600"
													)}
													aria-hidden="true"
												/>
												<span
													className={classNames(
														active ? "border" : "border-2",
														checked
															? "border-primary-500"
															: "border-transparent",
														"pointer-events-none absolute -inset-px rounded-lg"
													)}
													aria-hidden="true"
												/>
											</>
										)}
									</RadioGroup.Option>
								))}
							</div>
						</RadioGroup>
					</div>

					<div className="mt-9" style={{ marginRight: '75%' }}>
						<Link
							to={`${categoriSelect?.id_categorieProduit}`}
							disabled
							className="flex justify-center rounded-md border border-transparent bg-primary-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
							{" "}
							Continuer
						</Link>
					</div>


				</div>

				<div
					className={clsx(
						"col-span-6 sm:col-span-2 lg:col-span-2 sm:mx-8 lg:mx-1 mt-5 "
					)}>


					<div className="mt-12">
						<img src={select}></img>
					</div>

				</div>

			</div>)
	} else {
	}

	return content;
}
