/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component RadioGroup
 * @description composant qui affiche une question à choix unique pour un formulaire
 * @props options, question, onChange, id
 * @
 */

const RadioGroup = ({ options, question, onChange, id }) => {
	return (
		<div>
			{/* question  */}
			<label className="text-base font-medium text-gray-900">
				{question}
			</label>

			<fieldset className="mt-4">
				<legend className="sr-only">{question}</legend>

				{/* inputs de type radio  */}
				<div className="space-y-4">
					{options.map((option, index) => (
						<div
							key={`${option.label}-${index}`}
							className="flex items-center">
							<input
								value={`${option.label}`}
								id={`${option.label}-${index}`}
								name={`${id}-${question}`}
								onChange={onChange}
								type="radio"
								checked={option.selected ? "checked" : undefined}
								className="h-4 w-4 border-gray-300 text-secondary-600 focus:ring-secondary-500"
							/>
							<label
								htmlFor={`${option.label}-${index}`}
								className="ml-3 block text-sm text-gray-700">
								{option.label}
							</label>
						</div>
					))}
				</div>
			</fieldset>
		</div>
	);
};

export default RadioGroup;
