/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add incident chat modal
 * @component Message
 */
import React from "react";
import { useSelector } from "react-redux";
import { getUserCode } from "../../../../features/user/user.slice";
import { classNames } from "../../../../styles";

const Message = (props) => {
  // user code
  const codeUser = useSelector(getUserCode);

  // props
  const { sender, message, date, fichiers } = props;

  // is comming message
  const recevedMessage = sender.code === codeUser;

  // get date
  const getHour = (date) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };

    const dateRdv = new Date(date);
    const hourRdv = new Intl.DateTimeFormat("fr-FR", options).format(dateRdv);

    return hourRdv;
  };

  return (
    <>
      <div
        className={classNames(
          "flex items-start gap-x-1",
          recevedMessage && "justify-end"
        )}
      >
        {/* profil  */}
        {!recevedMessage && (
          <img
            className="inline-block h-6 w-6 rounded-full object-cover"
            src={sender.photo_profil}
            alt=""
          />
        )}

        <div className="flex flex-col items-start gap-y-1 max-w-xs ">
          {/* name */}
          {!recevedMessage && (
            <span className="truncate text-[10px] lowercase text-gray-600">
              {sender.nom}
            </span>
          )}

          <div
            className={classNames(
              "flex flex-col px-4 rounded-lg rounded-tl-none space-y-1 py-1",
              !recevedMessage ? "bg-secondary-100" : "bg-slate-100"
            )}
          >
            {/* files  */}
            {fichiers?.length > 0 && (
              <img
                src={fichiers[0].uri}
                alt={fichiers[0].name}
                height="96px"
                width="96px"
                className="border object-center rounded-lg mb-1 w-24 h-24"
              />
            )}

            {/* text  */}
            <div className=" text-xs font-medium text-gray-700">
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>

            {/* hour */}
            <div className="flex justify-end space-x-1 text-[10px] leading-3 w-full ">
              <span className="text-gray-500 font-medium">{getHour(date)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
