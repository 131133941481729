import { useState, useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "./sidebar";
import DashboardHeader from "./header/DashboardHeader";

import Notification from "../../UI/notification";

export default function Layout() {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const [show, setShow] = useState(false);

	useLayoutEffect(() => {
		if (show) {
			setTimeout(() => {
				setShow(false);
			}, 4000);
		}

		return () => {
			clearTimeout();
		};
	}, [show]);

	return (
		<>
			<div>
				{/* siderbar  */}
				<Sidebar
					sidebarOpen={sidebarOpen}
					setShow={setShow}
					setSidebarOpen={setSidebarOpen}
				/>

				<div className="flex flex-1 flex-col xl:ml-64">
					{/* header */}
					<DashboardHeader
						sidebarOpen={sidebarOpen}
						setSidebarOpen={setSidebarOpen}
					/>

					{/* main  */}
					<Outlet />
				</div>
			</div>

			{/* notification  */}
			{show && (
				<Notification
					title={"Profil mis à jour avec success"}
					show={show}
					setShow={setShow}
				/>
			)}
		</>
	);
}
