/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list category insuranece page
 * @component ListCategoriePage
 */
import React from "react";

import ListCategorie from "../../../components/layouts/main/assurances/client/ListCategorie";

const ListCategoriePage = () => {
	return (
		<div className=" max-w-7xl sm:px-6 lg:px-8  p-8 mt-8">
			<ListCategorie />
		</div>
	);
};

export default ListCategoriePage;
