/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component InputTextQuestion
 * @description composant champs d'entre de type text pour la generation automatique d'un formulaire
 * @props title, idform, formsEntities
 * @
 */

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { setQuestionForm } from "../../features/form/form.slice";

const InputTextQuestion = ({ title, idform, formsEntities }) => {
	const dispatch = useDispatch();

	const defautOptions = formsEntities[idform]?.options;

	const setQuestionPrixHandler = useCallback(
		(e, id) => {
			const value = e.target.value;

			const newOption = {
				...defautOptions[0],
				prix: value,
			};

			dispatch(
				setQuestionForm({
					type: "OPTIONSPRIX",
					option: newOption,
					id,
					indexOption: 0,
					idOption: defautOptions[0]?.id,
				})
			);
		},
		[dispatch, defautOptions]
	);

	return (
		<div className="flex flex-col sm:flex-row gap-y-4 mt-2">
			<div className="sm:w-2/3  text-sm border-0 border-b border-gray-400 border-dotted text-gray-400 ">
				{title}
			</div>

			<label
				htmlFor="prix"
				className=" sm:w-1/3  sm:pl-8 ">
				<input
					onChange={(e) => setQuestionPrixHandler(e, idform)}
					type="number"
					name="prix"
					id="prix"
					defaultValue={defautOptions[0]?.prix}
					min={0}
					className="block w-full border-0 border-b border-gray-500 bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md text-gray-700 sm:h-10 h-8"
					placeholder="Prix"
				/>
			</label>
		</div>
	);
};

export default InputTextQuestion;
