/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurance of insurer page
 * @component ListAssurancesOwnerPages
 */

import React from "react";
import ListSinistre from "../../../components/layouts/main/assurances/provider/listSinistre";

const ListSinistreOwnerPages = () => {
	return <ListSinistre />;
};

export default  ListSinistreOwnerPages;
