/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list  insurance product
 * @component ListAssurancesOwner
 */
import { useLayoutEffect, Fragment, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "../../UserRecharge/Recharge";
import {
	useGetAllOwnerAssurancesQuery,
	useDeleteproductMutation,
	useActiveproductMutation,
} from "../../../../../features/api/apiSlice";
import { getUserCode } from "../../../../../features/user/user.slice";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import {
	EllipsisHorizontalIcon,
	LockOpenIcon,
	LockClosedIcon,
	EyeIcon,
} from "@heroicons/react/20/solid";
import Spinner from "../../../../../UI/spinner/Spinner";
import {
	getModalProps,
	getModalType,
	initialModal,
	setModal,
} from "../../../../../features/feedBack/modal.slice";
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ListAssurancesOwner = () => {
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

	const dispatch = useDispatch();

	// delete insurance request
	const [deleteAssur] = useDeleteproductMutation();

	// active insurance request
	const [activeAssur] = useActiveproductMutation();

	// code user
	const code = useSelector(getUserCode);

	// get  insurance request
	const {
		data: assurances,
		isSuccess,
		isLoading,
	} = useGetAllOwnerAssurancesQuery(code);

	const [selectedAssurance, setSelectedAssurance] = useState(null);
	const [rowClick, setRowClick] = useState(true);



	const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
	const paginatorRight = <Button type="button" icon="pi pi-download" text />;


	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },

	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	//show header
	const renderHeader = () => {
		return (
			<div className="flex flex-wrap gap-2 justify-content-end align-items-end" style={{ justifyContent: 'end' }}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="rechercher" />
				</span>
			</div>
		);
	};

	//set value to filter

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	//show user.status
	const statusTemplate = (rowData) => {

		return (

			<td
				className={statusClassName(
					rowData.etat
				)}>
				{rowData.etat}
			</td>

		)


	}


	const header = renderHeader();

	// open options parameters(edit and delete)
	const actionBodyTemplate = (rowData) => {
		return <Menu
			as="div"
			className=" inline-block text-left">
			<div>
				<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
					<span className="sr-only">
						Open
						options
					</span>
					<EllipsisHorizontalIcon
						className="h-7 w-7"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>

			<Transition
				as={
					Fragment
				}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95">
				<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						<Menu.Item>
							{({
								active,
							}) => (
								<button
									onClick={updateAssue.bind(
										null,
										rowData.code
									)}
									href="#"
									className={classNames(
										active
											? "bg-gray-100 text-gray-900"
											: "text-gray-700",
										"group flex items-center w-full px-4 py-2 text-sm"
									)}>
									<EyeIcon
										className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500"
										aria-hidden="true"
									/>
									Consulter
								</button>
							)}
						</Menu.Item>
					</div>
					<div className="py-1">
						{rowData.statut ===
							"Désactivé" ? (
							<Menu.Item>
								{({
									active,
								}) => (
									<button
										onClick={ActiveAsurHandleClick.bind(
											null,
											rowData.code
										)}
										href="#"
										className={classNames(
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700",
											"group flex items-center w-full px-4 py-2 text-sm"
										)}>
										<LockOpenIcon
											className="mr-3 h-5 w-5 text-green-400 group-hover:text-green-500"
											aria-hidden="true"
										/>
										Activer
									</button>
								)}
							</Menu.Item>
						) : rowData.statut ===
							"Actif" ? (
							<Menu.Item>
								{({
									active,
								}) => (
									<button
										onClick={deleteAsurHandleClick.bind(
											null,
											rowData.code
										)}
										href="#"
										className={classNames(
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700",
											"group flex items-center w-full px-4 py-2 text-sm"
										)}>
										<LockClosedIcon
											className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
											aria-hidden="true"
										/>
										Supprimer
									</button>
								)}
							</Menu.Item>
						) : null}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	};


	const navigate = useNavigate();

	// update insurance
	const updateAssue = (code) => {
		navigate(`/mes-assurances/${code}`);
	};

	// delete insurance
	const deleteAsurHandleClick = (code) => {
		deleteAssur(code)
			.unwrap()
			.then((res) => {
				// add alert delete assur
				dispatch(
					setModal({
						modalType: "assur",
						modalProps: {
							isOpen: true,
							status: "success",
							text: "Produit d'assurance désactié avec succès",
						},
					})
				);
			})
			.catch((err) => { });
	};

	// active insurance
	const ActiveAsurHandleClick = (code) => {
		activeAssur(code)
			.unwrap()
			.then((res) => {
				// add alert active assur
				dispatch(
					setModal({
						modalType: "assur",
						modalProps: {
							isOpen: true,
							status: "success",
							text: "Produit d'assurance activé avec succès",
						},
					})
				);
			})
			.catch((err) => { });
	};

	// close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 s
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);

	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "Actif":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Désactivé":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap ";

			default:
				break;
		}
	};

	return (
		<>
			{/* alert component  */}
			{modalType === "assur" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}

			<div className="px-8 sm:px-6 lg:px-8 mt-8">
				{/* header  */}
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-primary-800">
							Assurances
						</h1>
						<p className="mt-2 text-sm text-gray-700">

						</p>
					</div>
					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<Link to="nouveau">
							<button
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
								Ajouter une assurance
							</button>
						</Link>
					</div>
				</div>
				{isSuccess && assurances?.data?.length === 0 ? (
					<div className="flex flex-col items-center justify-center">
						{" "}
						<p className="text-3xl text-center text-gray-500">
							aucune assurance{" "}
						</p>
					</div>
				) : (
					<>
						<div className=" pt-8 sm:pt-6 lg:pt-8 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<DataTable value={assurances?.data} selectionMode={rowClick ? null : 'checkbox'} selection={selectedAssurance} onSelectionChange={(e) => setSelectedAssurance(e.value)} header={header} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
									paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
									filters={filters} globalFilterFields={['nom', 'prenom', 'role', 'email', 'statut']}
									emptyMessage="No customers found."
									currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} style={{ borderWidth: '1px', borderStyle: 'solid' }}>
									<Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>

									<Column field="code" header="Code" style={{ width: '2%' }}></Column>

									<Column field="nom" sortable header="Assurance" style={{ width: '4%' }}></Column>

									<Column field="fournisseur" header="Fournisseur" style={{ width: '2%' }}></Column>

									<Column field="categorie.nom" header="Categorie" style={{ width: '2%' }}></Column>
									<Column field="prix" header="Prix" style={{ width: '1%' }}></Column>
									<Column field="dateCreation" header="Date de creation" style={{ width: '4%' }}></Column>

									<Column header="Status" style={{ width: '1%' }} body={statusTemplate} />


									<Column header="Action" style={{ width: '1%' }} body={actionBodyTemplate} />

								</DataTable>
							</div>
						</div>


					</>
				)}

				{isLoading && (
					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
				)}
			</div>
		</>
	);
};

export default ListAssurancesOwner;
