/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  sending message form chat
 * @component FormChat
 */
import React, { useEffect, useRef, useState } from "react";
import {
  useAddMessageMutation,
  useGetUsersChatQuery,
} from "../../../../features/api/apiSlice";
import { socket } from "../../../../socket";
import { useSelector } from "react-redux";
import { getUser } from "../../../../features/user/user.slice";
import AddfileMessage from "./AddfileMessage";
import AddEmojiMessage from "./AddEmojiMessage";

const FormChat = ({ selectedChat }) => {
  // formdata
  const formData = new FormData();

  // message ref
  const messageRef = useRef();

  const [value, setvalue] = useState("");

  // users chat state
  const [usersChat, setusersChat] = useState([]);

  // file state
  const [file, setfile] = useState();

  // get user
  const userConnected = useSelector(getUser);

  // get user chat request
  const { data: users, isSuccess: usersIsSuccess } = useGetUsersChatQuery(
    selectedChat?.conversation_id
  );

  // add message request
  const [addMessage, { addMessageIsLoading }] = useAddMessageMutation();

  // set users chat state
  useEffect(() => {
    if (usersIsSuccess) {
      setusersChat(users.data);
    }

    return () => {};
  }, [usersIsSuccess, users]);

  // reset message
  useEffect(() => {
    messageRef.current.value = "";
    setfile();

    return () => {};
  }, [selectedChat?.conversation_id]);

  const pushItemToFormData = (item, key) => {
    return formData.append(`${key}`, item);
  };

  const typingHandle = (e) => {
    const { value } = e.target;
    setvalue(value);
  };

  // add emoji
  const handleEmojiClck = (emoji) => {
    const input = messageRef.current;
    if (messageRef) {
      const selectionStart = input.selectionStart;
      const selectionEnd = input.selectionEnd;

      setvalue(
        (prevSate) =>
          prevSate.substring(0, selectionStart) +
          emoji +
          prevSate.substring(selectionEnd)
      );

      input.selectionStart = input.selectionEnd = selectionStart + 1;
    }
  };

  // send message
  const sendMessage = (e) => {
    pushItemToFormData(value, "message");
    pushItemToFormData(selectedChat.conversation_id, "destination");
    pushItemToFormData(file, "files");

    const sendMessageRequest = () =>
      addMessage(formData)
        .unwrap()
        .then((res) => {
          setvalue("");
          setfile(null);
          console.log(socket.connected);
          socket.emit("new message", {
            message: {
              ...res.data,
              sender: {
                nom: userConnected.nom,
                prenom: userConnected.nom,
                code: userConnected.nom,
                photo_profil: userConnected.photo_profil,
              },
            },
            chat: { ...selectedChat, users: usersChat },
          });
        })
        .catch((err) => {
          // console.log(err);
        });

    // keydown enter
    if (e.type === "keydown" && e.key === "Enter") {
      e.preventDefault();
      sendMessageRequest();
    } else if (e.type === "click") {
      // button click
      e.preventDefault();
      sendMessageRequest();
    } else {
      return;
    }
  };

  return (
    <>
      <div className=" p-4">
        <form onSubmit={sendMessage} className="flex flex-col ">
          {/* image preview  */}
          {file && (
            <div className="flex gap-x-2">
              <img
                src={URL.createObjectURL(file)}
                alt={file?.name}
                height="96px"
                width="96px"
                className="border object-center rounded-lg mb-1 w-24 h-24"
              />
            </div>
          )}

          <div className="border-t-2 border-gray-300 focus-within:border-secondary-600">
            <label htmlFor="comment" className="sr-only">
              Saisir le message
            </label>

            {/* text area  */}
            <textarea
              onKeyDown={sendMessage}
              rows={2}
              value={value}
              name="comment"
              id="comment"
              ref={messageRef}
              onChange={typingHandle}
              className="block w-full resize-none border-0 border-t border-transparent p-0 pt-2 text-gray-900 placeholder:text-gray-400 focus:border-secondary-600 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Saisir le message..."
            />
          </div>

          <div className="flex justify-between pt-2">
            <div className="flex items-center space-x-5">
              {/* add file image  */}
              <AddfileMessage setfile={setfile} />

              {/* add emoji  */}
              <AddEmojiMessage handleEmojiClck={handleEmojiClck} />
            </div>

            {/* sending button  */}
            <div className="flex-shrink-0">
              <button
                disabled={addMessageIsLoading}
                onClick={sendMessage}
                type="submit"
                className="inline-flex items-center rounded-md bg-secondary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-600"
              >
                Envoyer
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormChat;
