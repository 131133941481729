/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  add image message
 * @component AddfileMessage
 */
import { PhotoIcon } from "@heroicons/react/24/outline";
import Input from "../../../../UI/form/Input";
import React from "react";

const AddfileMessage = ({ setfile }) => {
	// handle change images
	const handleFileChange = (e) => {
		const { files } = e.target;
		setfile(files[0]);
	};

	return (
		<div className="flow-root">
			<button
				type="button"
				className="-m-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
				<label htmlFor="file">
					<PhotoIcon
						className="h-6 w-6"
						aria-hidden="true"
					/>
				</label>
				{/* input file  */}
				<Input
					onChange={handleFileChange}
					input={{
						id: "file",
						type: "file",
						accept: "image/*",
					}}
					className="hidden"
					hidden={true}
					name="file"
				/>

				<span className="sr-only">Ajouter un fichier</span>
			</button>
		</div>
	);
};

export default AddfileMessage;
