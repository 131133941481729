/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list all  insurance product
 * @component ListAssurances
 */
import { useLayoutEffect, Fragment, useCallback,useState } from "react";
import { Alert } from "../../UserRecharge/Recharge";
import { Link, useNavigate } from "react-router-dom";
import {

	useGetAllSinistresByproductQuery,
} from "../../../../../features/api/apiSlice";
import {
	getUserCode,
	
} from "../../../../../features/user/user.slice";

import { setModal } from "../../../../../features/feedBack/modal.slice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../UI/spinner/Spinner";
import {
	getModalProps,
	getModalType,
	initialModal,
} from "../../../../../features/feedBack/modal.slice";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ListInistre = () => {
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps);

	const dispatch = useDispatch();
    
	// user code
	const code = useSelector(getUserCode);
	// get all sinistre request
	const {
		data: sinistres,
		isSuccess,
		isLoading,
	} = useGetAllSinistresByproductQuery(code);
console.log(sinistres)
	const itemsPerPage = 10;

	//data tabele
	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS}
	});
    const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
	const [selectedsinistre, setSelectedsinistre] = useState(null);
	const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
	

	const navigate = useNavigate();

	// close alert
	const closeAlert = useCallback(() => {
		dispatch(initialModal());
	}, [dispatch]);

	// close alert after 10 s
	useLayoutEffect(() => {
		if (modalProps?.isOpen) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [modalProps?.isOpen, closeAlert]);
	
	const header = renderHeader();

	return (
		<>
			{/* alert component  */}
			{modalType === "assur" && modalProps?.isOpen ? (
				<div className="px-4">
					<Alert
						closeAlert={closeAlert}
						text={modalProps?.text}
						status={modalProps?.status}
					/>
				</div>
			) : null}

			<div className="px-8 sm:px-6 lg:px-8 mt-8">
			<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-primary-800">
							Sinistres 
						</h1>
						
					</div>
					
				</div>
				{/* header  */}
				{isSuccess && (
              <div className="card">
            <DataTable value={sinistres?.data} paginator showGridlines  selectionsinistres="checkbox" selection={selectedsinistre} onSelectionChange={(e) => setSelectedsinistre(e.value)} rows={10} 
                    filters={filters} globalFilterFields={['nom','description']} header={header}
                    emptyMessage="No customers found.">
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
				<Column  sortable  header="titre" field="titre" style={{ minWidth: '12rem' }}  />
                <Column sortable  header="description" field="description" style={{ minWidth: '10rem' }}   /> 
                
            </DataTable> 
        </div>
			)}
				

					<>
					
					</>
				
				{/* loader  */}
				{isLoading && (
					<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
				)}
			</div>
		</>
	);
};

export default ListInistre;
