/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance  souscribe by user page
 * @component MesProduitsPage
 */

import Main from "../../../components/layouts/main";

import MesProduits from "../../../components/layouts/main/assurances/client/Mes_produits";

const MesProduitsPage = (props) => {
	return <Main children={<MesProduits />} />;
};

export default MesProduitsPage;
