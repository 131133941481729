/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance detail page
 * @component AssurancePreviewPage
 */

import React from "react";
import AssurancePreview from "../../../components/layouts/main/assurances/client/AssurancePreview";

const AssurancePreviewPage = () => {
	return <AssurancePreview />;
};

export default AssurancePreviewPage;
