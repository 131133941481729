/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  verifiy email after registration when user click to the link in email
 * @component VerifCodePage
 */
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import axiosInstance from "../../apis/inchAssur";

import Button from "../../UI/button/Button";

import Logo from "../../components/logo/Logo";

import { setModal } from "../../features/feedBack/modal.slice";

const VerifCodePage = () => {
	// searchParams state
	const [searchParams] = useSearchParams();
	// get query params
	const query = Object.fromEntries([...searchParams]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isAccountVerified, setIsAccountVerified] = useState(false);
	// const [isError, setIsError] = useState(false);

	useEffect(() => {
		const data = {
			code: query?.code,
		};

		if (!isAccountVerified) {
			axiosInstance
				.post("/account/confirm", data, {
					headers: {
						Authorization: "Bearer " + query?.token,
					},
				})
				.then((res) => {
					setIsAccountVerified(true);
				})
				.catch((err) => {
					if (err.response.status === 400 && !isAccountVerified) {
						dispatch(
							setModal({
								modalType: "account",
								modalProps: {
									isOpen: true,
									status: "success",
									text: `Ce compte à déjà été vérifié. Veillez vous connecter  `,
								},
							})
						);
						navigate(`/connexion`, { replace: true });
					}
					// setIsError(true);
				});
		}

		return () => {};
	}, [dispatch, navigate, isAccountVerified, query]);

	return (
		<>
			{isAccountVerified && (
				<div className=" min-h-screen ">
					<div className="container max-w-lg mx-auto pt-12 space-y-8 ">
						<Logo />

						<div className="border shadow-md p-4 flex flex-col justify-center items-center space-y-6 rounded-lg">
							<h1 className="text-2xl font-bold text-primary-800 ">
								Verification de compte
							</h1>

							<p className="text-base ">
								{" "}
								Votre compte a été vérifié avec success. Veillez
								vous connecter pour acceder à votre ce dernier.
							</p>

							<Button
								children="Se connecter"
								button="primary"
								type="button"
								onClick={() => navigate(`/connexion`)}
								className="max-w-max"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default VerifCodePage;
