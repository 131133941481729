/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurances
 * @component ListAssurances
 */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AssuranceCard from "./AssuranceCard";

import { useGetAssurancesQuery } from "../../../../../features/api/apiSlice";

import Spinner from "../../../../../UI/spinner/Spinner";
import Button from "../../../../../UI/button/Button";

const ListAssurances = () => {
	const navigate = useNavigate();

	// insurance state
	const [assurance, setassurance] = useState([]);

	// get assurance request
	const { data, isSuccess, isLoading } = useGetAssurancesQuery();

	let content;

	// set data insurances
	useEffect(() => {
		if (isSuccess) {
			setassurance(
				data?.data?.map((item) => {
					return {
						id: item.id_produit,
						description: item.description,
						duree: item.duree,
						images: item.images,
						titre: item.nom,
						code: item.code,
						statut: item.statut,
						prix: parseInt(item.prix),
						fournisseur: item.fournisseur,
						services: item.listeServices,
						pieces_a_joindre: item.pieces_a_joindre,
						type_contrat: item.type_contrat,
						categorie: item.categorie,
						documents: item.documents,
					};
				})
			);
		}

		return () => {};
	}, [isSuccess, data]);

	if (isLoading) {
		content = (
			<div className="flex flex-1 justify-center">
				<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
			</div>
		);
	} else if (isSuccess) {
		content = (
			<div className="bg-white">
				<div>
					<Button
						children="etape precedente"
						type="button"
						onClick={() => navigate(`/assurances/categorie`)}
					/>
				</div>
				<div className="mx-auto max-w-2xl  lg:max-w-7xl lg:px-8 inset-inline-start">
					<h1 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
						Selectionner une assurance{" "}
					</h1>
					

					<div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
						{assurance?.map((item, index) => {
							return (
								<AssuranceCard
									key={index}
									duree={item.duree}
									description={item.description}
									titre={item.titre}
									images={item.images[0]?.uri}
									categorie={item.categorie.nom.toLowerCase()}
									code={item.code}
									prix={item.prix}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	} else {
		return;
	}

	return content;
};

export default ListAssurances;
