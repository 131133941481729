import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
//theme
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";   
import "primeicons/primeicons.css";  
    
//core
import "primereact/resources/primereact.min.css";        
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter, Routes, Route } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));

let persistor = persistStore(store);
/**
 * This is a coment just to add modifications
 * and verify the git tracking
 */
root.render(
	<React.StrictMode>
		<Provider store={store}>
		
			<BrowserRouter>
				<PersistGate persistor={persistor}>
					<Routes>
						<Route
							path="/*"
							element={<App />}
						/>
					</Routes>
				</PersistGate>
			</BrowserRouter>
			
		</Provider>
	</React.StrictMode>
);
