/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list sinistre user page
 * @component SinistresPage
 */

import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Transition, Menu } from "@headlessui/react";
import {
	EllipsisHorizontalIcon,
	EyeIcon,
	TrashIcon,
} from "@heroicons/react/24/solid";

import { useGetUserSinistresQuery } from "../features/api/apiSlice";
import { getUserCode, getUserDefaultProfil } from "../features/user/user.slice";

import Spinner from "../UI/spinner/Spinner";

import { statusClassName } from "../lib/status";
import { subdescription } from "../lib/Assurance";

import { SinistreModal } from "./SinistreModal";
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const SinistresPage = () => {
	// user code
	const userCode = useSelector(getUserCode);

	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "Terminé":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "En cours":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap ";

			default:
				break;
		}
	};

	const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
	const paginatorRight = <Button type="button" icon="pi pi-download" text />;


	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },

	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	//show header
	const renderHeader = () => {
		return (
			<div className="flex flex-wrap gap-2 justify-content-end align-items-end" style={{ justifyContent: 'end' }}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="rechercher" />
				</span>
			</div>
		);
	};

	//set value to filter

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	//show user.status
	const statusTemplate = (rowData) => {

		return (

			<td
				className={statusClassName(
					rowData.statut
				)}>
				{rowData.statut}
			</td>

		)


	}


	// open options parameters(edit and delete)
	const actionBodyTemplate = (rowData) => {
		return <Menu
															as="div"
															className=" inline-block text-left">
															<div>
																<Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
																	<span className="sr-only">
																		Open
																		options
																	</span>
																	<EllipsisHorizontalIcon
																		className="h-7 w-7"
																		aria-hidden="true"
																	/>
																</Menu.Button>
															</div>

															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95">
																<Menu.Items className="absolute right-14 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																	<div className="py-1">
																		<Menu.Item>
																			{({
																				active,
																			}) => (
																				<button
																					className={classNames(
																						active
																							? "bg-gray-100 text-gray-900"
																							: "text-gray-700",
																						"group flex items-center w-full px-4 py-2 text-sm"
																					)}>
																					<EyeIcon
																						className="mr-3 h-5 w-5 text-secondary-400 group-hover:text-secondary-500"
																						aria-hidden="true"
																					/>
																					Suivre
																				</button>
																			)}
																		</Menu.Item>
																		{idUser ===
																			"IA10" && (
																			<Menu.Item>
																				{({
																					active,
																				}) => (
																					<button
																						className={classNames(
																							active
																								? "bg-gray-100 text-gray-900"
																								: "text-gray-700",
																							"group flex items-center w-full px-4 py-2 text-sm"
																						)}>
																						<TrashIcon
																							className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
																							aria-hidden="true"
																						/>
																						Terminer
																					</button>
																				)}
																			</Menu.Item>
																		)}
																	</div>
																</Menu.Items>
															</Transition>
														</Menu>
	};


	const header = renderHeader();

	// user default profil
	const { id: idUser } = useSelector(getUserDefaultProfil);

	// get sinistres request
	const {
		data: sinistres,
		isLoading,
		isSuccess,
	} = useGetUserSinistresQuery(userCode);

	// add sinistre modal state
	const [openModal, setopenModal] = useState(false);
	const [selectedSinistre, setSelectedSinistre] = useState(null);
	const [rowClick, setRowClick] = useState(true);

	return (
		<>
			{/* add sinistre modal  */}
			{openModal && (
				<SinistreModal
					open={openModal}
					setOpen={setopenModal}
				/>
			)}

			<div className="px-8 sm:px-6 lg:px-8 mt-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-semibold text-primary-800">
							Sinistres
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							Liste de tous les sinistres.
						</p>
					</div>
					<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
						<button
							onClick={() => setopenModal(true)}
							type="button"
							className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:w-auto">
							Déclarer un sinistre
						</button>
					</div>
				</div>

				{isSuccess ? (
					sinistres?.data?.length === 0 ? (
						<div className="flex flex-col items-center justify-center">
							{" "}
							<p className="text-3xl text-center text-gray-500 mt-8">
								aucun sinistre déclaré{" "}
							</p>
						</div>
					) : (
						<div className=" pt-8 sm:pt-6 lg:pt-8 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<DataTable value={sinistres?.data} selectionMode={rowClick ? null : 'checkbox'} selection={selectedSinistre} onSelectionChange={(e) => setSelectedSinistre(e.value)} header={header} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
									paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
									filters={filters} globalFilterFields={['nom', 'prenom', 'role', 'email', 'statut']}
									emptyMessage="No customers found."
									currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} style={{ borderWidth: '1px', borderStyle: 'solid' }}>
									<Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>

									<Column field="sinistreID" header="incidentID" style={{ width: '2%' }}></Column>

									<Column field="titre" sortable header="Titre" style={{ width: '4%' }}></Column>

									<Column field="description" header="Description" style={{ width: '2%' }}></Column>

									<Column field="produitCode" header="Code du produit" style={{ width: '2%' }}></Column>
									
									<Column field="dateCreation" header="Date de creation" style={{ width: '4%' }}></Column>

									<Column header="Status" style={{ width: '1%' }} body={statusTemplate} />


									<Column header="Action" style={{ width: '1%' }} body={actionBodyTemplate} />

								</DataTable>
							</div>
						</div>
					)
				) : null}

				{isLoading && (
					<div className="flex justify-center">
						<Spinner className="w-10 h-10 text-primary-700" />
					</div>
				)}
			</div>
		</>
	);
};

export default SinistresPage;
