/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description    sinistre chat
 * @component SinistreItemChat
 */
import React from "react";
import { useSelector } from "react-redux";
import { getSelectedChat } from "../../../../features/chats/chats.slice";
import { classNames } from "../../../../styles";

const SinistreItemChat = ({
	conversation_id,
	nom,
	type,
	img,
	selectChat,
	code,
}) => {
	const chat = {
		nom,
		conversation_id,
		type,
		img,
		code,
	};
	// selected chat
	const selectedChat = useSelector(getSelectedChat);

	// is chat selected
	const chatIsSelected = conversation_id === selectedChat?.conversation_id;

	return (
		<li
			onClick={() => selectChat(chat)}
			className={classNames(
				"overflow-hidden rounded-md flex group items-start gap-x-3 w-full px-2 py-3 cursor-pointer hover:bg-secondary-100",
				chatIsSelected && "bg-secondary-100"
			)}>
			<span className="px-2 rounded-full bg-yellow-500 text-white text-center text-xl font-semibold">
				#
			</span>
			<div className=" flex-1">
				<div className=" flex items-center justify-between ">
					<p className="text-sm font-bold text-secondary-700 ">
						{`${code ?? ""}-${nom}`}
					</p>
				</div>
				<div className="flex items-center justify-between">
					<p className="text-xs font-bold text-gray-500 ">En cours</p>
				</div>
			</div>
		</li>
	);
};

export default SinistreItemChat;
