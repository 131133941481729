/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product
 * @component AddAssurance
 */
import { Fragment, useState } from "react";
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";

import Description from "./Description";
import Formulaire from "./Formulaire";
import Services from "./Services";
import Document from "./Document";

import {
	getFormsEntities,
	resetForm,
} from "../../../../../features/form/form.slice";
import { useAddProductAssuranceMutation } from "../../../../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { getUserDefaultProfil } from "../../../../../features/user/user.slice";
import { setModal } from "../../../../../features/feedBack/modal.slice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const AddAssurance = () => {
	// user default profil
	const profil = useSelector(getUserDefaultProfil);
	const profilID = profil?.id;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	// add product insurance request
	const [addProduct, { isLoading }] = useAddProductAssuranceMutation();

	// get form
	const formsEntities = useSelector(getFormsEntities);

	//initial product state
	const [dataAssurance, SetDataAssurance] = useState({
		document: {},
		services: [],
		pieces: [],
		imagePreview: [],
		images: [],
		categorie: "default",
	});

	// destrucration of initial product state
	const {
		services,
		pieces,
		nom,
		prix,
		duree,
		images,
		document,
		description,
		categorie,
		couvertureMax,
	} = dataAssurance;

	// array of document
	const documentArray = Object.values(document);

	// tabs
	const tabs = [
		{
			title: "Description",
		},
		{
			title: "Services et pieces à joindres",
		},
		{
			title: "Documents associés",
		},
		{
			title: "Formulaire associé",
		},
	];

	// formdata
	const formDate = new FormData();

	// serialize data
	function getFormData(item, index, name) {
		const formTransForm = Object.keys(item).map((key) => {
			if (key === "options") {
				const options = item[key].map((option, indexOption) => {
					return Object.keys(option).map((keyOption) =>
						formDate.append(
							`${name}[${index}][${key}][${indexOption}][${keyOption}]`,
							option[keyOption]
						)
					);
				});

				return options;
			} else {
				return formDate.append(`${name}[${index}][${key}]`, item[key]);
			}
		});

		return formTransForm;
	}
	// end

	// add form input to formdata
	const pushDataformulaire = (array, name) => {
		const newformData = array.map((form, index) => {
			return getFormData(form, index, name);
		});

		return newformData;
	};

	// transform data to formData with blob, object
	const pushTableform = (array, key, object) => {
		if (object) {
			if (array?.length) {
				const documentName = array.map((item, index) => {
					return formDate.append(`${key}[${index}]`, item.title);
				});

				const documentFile = array.map((item, index) => {
					return formDate.append(
						`${key}[${index}]`,
						item.file,
						`file${index}.pdf`
					);
				});

				return [documentName, documentFile];
			} else {
				return [];
			}
		} else {
			if (array?.length) {
				return array.map((item, index) => {
					return formDate.append(`${key}[${index}]`, item);
				});
			} else {
				return [];
			}
		}
	};

	// add item to formdata
	const pushItemform = (item, key) => {
		return formDate.append(`${key}`, item);
	};

	// array of services id
	const serviceID = services.map((item) => {
		return item.id;
	});

	// array of pieces id
	const piecesName = pieces.map((item) => {
		return item.id;
	});

	// array of form questions
	const entities = Object.values(formsEntities);

	// get array data of form questions
	const form = entities.map((form) => {
		return {
			options: form.options,
			isRequired: form.isRequired,
			name: form.name,
			tarifType: form.tarifType,
			type: form.type,
		};
	});

	// display alert add products
	const dispacthModal = () => {
		dispatch(
			setModal({
				modalType: "assur",
				modalProps: {
					isOpen: true,
					status: "success",
					text: "Produit d'assurance ajouté avec succès",
				},
			})
		);
	};

	// add product insurance
	const handleSubmit = async (e) => {
		e.preventDefault();
		pushTableform(images, "images");
		pushTableform(documentArray, "documents", true);
		pushTableform(serviceID, "services");
		pushTableform(piecesName, "pieces");
		pushDataformulaire(form, "form");
		pushItemform(nom, "nom");
		pushItemform(description, "description");
		pushItemform(prix, "prix");
		pushItemform(duree, "duree");
		pushItemform(couvertureMax, "couvertureMax");
		pushItemform(categorie, "categorie");

		addProduct(formDate)
			.unwrap()
			.then((data) => {
				dispatch(resetForm());
				dispacthModal();
				if (profilID === "IA10") {
					navigate("/mes-assurances");
				} else {
					navigate("/mes_assurances");
				}
			})
			.catch((err) => console.error("Failed to save the product: ", err));
	};

	// tab index state
	const [selectedIndex, setSelectedIndex] = useState(0);

	const [totalDoc, settotalDoc] = useState(1);

	return (
		<>
			<Tab.Group
				as={"form"}
				className="space-y-8 mt-8"
				onSubmit={handleSubmit}
				selectedIndex={selectedIndex}
				onChange={setSelectedIndex}>
				{/* list tab  */}
				<Tab.List className="isolate flex divide-x divide-gray-200 rounded-lg shadow mx-4 ">
					{tabs.map((item, index) => {
						return (
							<Tab
								as={Fragment}  
								key={index}>
								{({ selected }) => (
									<div
										className={classNames(
											selected
												? "text-gray-900 outline-none"
												: "text-gray-500 hover:text-gray-700",
											"group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
										)}>
										<span>{item.title}</span>
										<span
											aria-hidden="true"
											className={classNames(
												selected
													? "bg-secondary-500"
													: "bg-transparent",
												"absolute inset-x-0 bottom-0 h-0.5  focus:bg-secondary-500"
											)}></span>
									</div>
								)}
							</Tab>
						);
					})}
				</Tab.List>
				<Tab.Panels className="mx-4  lg:p-4 p-2  ">
					{/* description pane  */}
					<Tab.Panel>
						<Description
							SetDataAssurance={SetDataAssurance}
							dataAssurance={dataAssurance}
							setSelectedIndex={setSelectedIndex}
							skip={true}
						/>
					</Tab.Panel>

					{/* services panel  */}
					<Tab.Panel>
						<Services
							SetDataAssurance={SetDataAssurance}
							dataAssurance={dataAssurance}
							setSelectedIndex={setSelectedIndex}
						/>
					</Tab.Panel>

					{/* document panel */}
					<Tab.Panel>
						<Document
							SetDataAssurance={SetDataAssurance}
							dataAssurance={dataAssurance}
							setSelectedIndex={setSelectedIndex}
							totalDoc={totalDoc}
							settotalDoc={settotalDoc}
						/>
					</Tab.Panel>

					{/* form panel */}
					<Tab.Panel>
						<Formulaire
							isLoading={isLoading}
							handleSubmit={handleSubmit}
							setSelectedIndex={setSelectedIndex}
						/>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</>
	);
};

export default AddAssurance;
