import React, { useState, useEffect } from "react";
import Button from "../../../../../UI/button/Button";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { useNavigate } from "react-router-dom";
const Recapdocumentstep = (props) => {
  const navigate = useNavigate();
  const {
    settype_contrat,
    setdescription,
    setduree,
    setfournisseur,
    setcategorie,
    formAnswer,
    setnom,
    setStep,
    steps,
    options,
    label,
    form,
  } = props;
  useEffect(() => {
    setfournisseur();
    setnom();
    setdescription();
    settype_contrat();
    setcategorie();
    setduree();

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const row2 = formAnswer.map((item) => (
    <span>
      {item.options.map((options) => (
        <span>
          {options.label}
        </span>
      ))}
    </span>
  ))
  const Tablerow = props.form.map((form, index) => (
    <div key={index} className={`${ index % 2 === 0 ? "bg-white" : "bg-gray-50"} px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3`}>
      <dt className="text-sm font-medium leading-6 text-gray-900"> {form.question}</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{row2[index]}</dd>
    </div>
  ))

  return (
    <div>

      <div className="my-4 flex items-center justify-between">
        <Button
          children="Etape précédente"
          type="button"
          onClick={() => setStep(steps[3], 3)}
        />
      </div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 le contrat className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
            Récapitulatif des informations précédement <br />fournies
          </h1>
        </div>
      </div>

      <div className="sm:flex sm:items-center justify-center ">
        <div className="sm:flex-auto ">

          <div>
            {/* <div className="sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Applicant Information</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>
      </div> */}
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Nom </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.nom}</dd>
                </div>
                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props?.description}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Type_contrat</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props?.type_contrat}</dd>
                </div>
                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900"> Fournisseur</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props?.fournisseur}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Categorie</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props?.categorie.nom}</dd>
                </div>
                {Tablerow}
              </dl>
            </div>
          </div>
          <div className="my-6 flex items-center justify-end">
            <Button
              children="Etape Suivante"
              type="button"
              onClick={() => setStep(steps[5], 5)}
            />
          </div>
        </div>
      </div>


    </div>
  );
};

export default Recapdocumentstep;
