/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  simple chat
 * @component SimpleItemChat
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../../features/user/user.slice";
import { getSelectedChat } from "../../../../features/chats/chats.slice";
import { classNames } from "../../../../styles";
import { useGetUsersChatQuery } from "../../../../features/api/apiSlice";

const SimpleItemChat = ({ selectChat, nom, conversation_id, type, img }) => {
	// get user chat list
	const {
		data: usersChat,
		isSuccess: usersChatIsSuccess,
		isLoading: usersChatIsloading,
	} = useGetUsersChatQuery(conversation_id, {
		skip: type !== "Message",
	});

	// users state
	const [users, setusers] = useState([]);

	// user
	const user = useSelector(getUser);

	// selected chat
	const selectedChat = useSelector(getSelectedChat);

	// is chat selected
	const chatIsSelected = conversation_id === selectedChat?.conversation_id;

	useEffect(() => {
		if (usersChatIsSuccess) {
			setusers(usersChat.data);
		}

		return () => {};
	}, [usersChatIsSuccess, usersChat]);

	// user chat
	const userChat = users.find((item) => item.code !== user.code);

	const name =
		type === "Groupe" ? nom : `${userChat?.nom} ${userChat?.prenom}`;

	const photo_profil = type === "Groupe" ? img : `${userChat?.photo_profil}`;

	const chat = {
		nom: name,
		conversation_id,
		type,
		img: photo_profil,
	};

	return (
		<>
			{/* loading  */}
			{usersChatIsloading && (
				<div className=" animate-pulse flex gap-x-2 items-center">
					<span className=" h-10 w-10 bg-slate-300 rounded-full"></span>
					<span className=" w-4/5 flex flex-col gap-y-2 ">
						<span className="bg-slate-300 h-2 rounded-md w-2/5"></span>
						<span className="bg-slate-300 h-2 rounded-md w-4/5"></span>
					</span>
				</div>
			)}

			{(type === "Groupe" || usersChatIsSuccess) && (
				<li
					onClick={() => selectChat(chat)}
					className={classNames(
						"overflow-hidden rounded-md flex group items-start gap-x-3 w-full px-2 py-3 cursor-pointer hover:bg-secondary-100",
						chatIsSelected && "bg-secondary-100"
					)}>
					<span className="relative">
						<img
							className="h-10 w-10 rounded-full object-cover"
							src={photo_profil}
							alt=""
						/>
					</span>
					<div className=" flex-1">
						<div className=" flex items-center justify-between">
							<p className="text-sm font-bold w-40 truncate text-secondary-700">
								{name}
							</p>
						</div>
						<div className="flex items-center justify-between">
							<p className="text-xs font-medium w-52 truncate text-gray-500">
								envoyer moi un cahier de charge
							</p>
						</div>
					</div>
				</li>
			)}
		</>
	);
};

export default SimpleItemChat;
