const Main = ({ children }) => {
	return (
		<main>
			<div className="py-6 mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
				{/* Breadcrumb  */}
				{/* {breadCrum} */}
				{/* End breadcrumb  */}

				{/* Replace with your content */}
				{children}
				{/* /End content */}
			</div>
		</main>
	);
};

export default Main;
