/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product description
 * @component Description
 */
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PhotoIcon } from "@heroicons/react/24/outline";

import { useGetAssurancesCategorieQuery } from "../../../../../features/api/apiSlice";
import { getToken } from "../../../../../features/user/auth.slice";

import Input from "../../../../../UI/form/Input";
import Select from "../../../../../UI/form/Select";
import TextArea from "../../../../../UI/form/TextArea";
import Button from "../../../../../UI/button/Button";
import Spinner from "../../../../../UI/spinner/Spinner";
import axiosInstance from "../../../../../apis/inchAssur";

import { useSelector } from "react-redux";

const Description = ({
	SetDataAssurance,
	dataAssurance,
	setSelectedIndex,
	modif,
	code,
}) => {
	// token
	const token = useSelector(getToken);

	// get insurance category
	const { data, isLoading } = useGetAssurancesCategorieQuery();

	// list of assurance categorie
	const valueOption = data?.data?.map((item) => {
		return {
			id: item.id_categorieProduit,
			name: item.nom,
		};
	});

	// handle change
	const HandleChange = (e) => {
		const type = e.target.type;
		if (type === "file") {
			let file = e.target.files[0];
			let imgUrl = URL.createObjectURL(file);

			SetDataAssurance((prevState) => {
				return {
					...prevState,
					images: [...prevState.images, file],
					imagePreview: [...prevState.imagePreview, { uri: imgUrl }],
				};
			});
		} else {
			const name = e.target.name;
			const value = e.target.value;

			SetDataAssurance((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		}
	};

	// delete image of assurance product
	const deleteImageHandleClick = (index, id, remove) => {
		// image preview to delete
		const previewToDelete = dataAssurance?.imagePreview[index];

		// image file to delete
		const imagesToDelete = dataAssurance?.images[index];

		const newImagePreview = dataAssurance?.imagePreview?.filter(
			(item) => item !== previewToDelete
		);

		const newImage = dataAssurance?.images?.filter(
			(item) => item !== imagesToDelete
		);

		if (remove) {
			// remove old image
			axiosInstance
				.delete(`product/${code}/img/${id}`, {
					headers: {
						authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					SetDataAssurance((prevState) => {
						return {
							...prevState,
							images: newImage,
							imagePreview: newImagePreview,
						};
					});
				})
				.catch(() => {});
		} else {
			SetDataAssurance((prevState) => {
				return {
					...prevState,
					images: newImage,
					imagePreview: newImagePreview,
				};
			});
		}
	};

	return (
		<div className="sm:px-8 px-4  grid grid-cols-3 gap-6">
			<div className="col-span-3 sm:col-span-1">
				{/* name  */}
				<Input
					label="Nom du produit d'assurance"
					input={{
						id: "nom",
						type: "text",
						maxLength: "80",
					}}
					onChange={HandleChange}
					defaultValue={dataAssurance?.nom}
					value={dataAssurance?.nom}
					name="nom"
					disabled={false}
					required={false}
				/>
			</div>
			<div className="col-span-3 sm:col-span-1">
				{isLoading ? (
					<Spinner className="w-8 h-8 text-primary-600" />
				) : (
					// category
					<Select
						label="Selectionner la catégorie"
						valueOption={valueOption}
						onChange={HandleChange}
						defaultValue={dataAssurance?.categorie}
						name="categorie"
						disabled={false}
						required={false}
					/>
				)}
			</div>

			{/* description  */}
			<div className="col-span-3 sm:col-span-2">
				<TextArea
					name="description"
					label="Description"
					rows="6"
					id="description"
					required={true}
					onChange={HandleChange}
					defaultValue={dataAssurance?.description}
				/>
			</div>

			{/* price  */}
			<div className="col-span-3 sm:col-start-1 sm:col-span-1">
				<Input
					label="Prix initial de l'assurance"
					defaultValue={dataAssurance?.prix}
					value={dataAssurance?.prix}
					onChange={HandleChange}
					input={{
						id: "prix",
						type: "number",
						min: 0,
						step: 500,
					}}
					name="prix"
					pattern="[0-9]+"
				/>
			</div>

			{/* date  */}
			<div className="col-span-3 sm:col-span-1 ">
				<Input
					label="Durée en jour"
					input={{ id: "jour", type: "number", min: 0 }}
					defaultValue={dataAssurance?.duree}
					value={dataAssurance?.duree}
					onChange={HandleChange}
					name="duree"
					pattern="[0-9]+"
				/>
			</div>

			{/* cover  */}
			<div className="col-span-3 sm:col-span-1 sm:col-start-1">
				<Input
					label="Couverture de l'assurance"
					input={{
						id: "couvertureMax",
						type: "number",
						min: 0,
					}}
					defaultValue={dataAssurance?.couvertureMax}
					value={dataAssurance?.couvertureMax}
					onChange={HandleChange}
					name="couvertureMax"
					pattern="[0-9]+"
				/>
			</div>

			{/* list preview images  */}
			<div className="col-span-3 sm:col-span-2 sm:col-start-1">
				<p
					className="text-sm font-medium my-2 text-gray-700"
					aria-hidden="true">
					image de couverture
				</p>
				<div className="mt-1 ">
					<div className="flex items-center gap-x-8">
						{dataAssurance?.imagePreview?.length ? (
							dataAssurance?.imagePreview.map((item, index) => {
								return (
									<div
										key={index}
										className=" h-32 w-32 relative border overflow-hidden rounded-md group"
										aria-hidden="true">
										{!dataAssurance?.imagePreview
											?.length ? (
											<PhotoIcon className="w-32 h-32 text-gray-500" />
										) : (
											<div>
												<img
													className="h-32 w-32 object-cover rounded-md"
													src={item.uri}
													alt=""
												/>
												<AiOutlineCloseCircle
													onClick={deleteImageHandleClick.bind(
														null,
														index,
														item.id,
														modif
													)}
													className="group-hover:block absolute  text-gray-700 cursor-pointer hover:text-red-600 top-0 right-0 h-5 w-5 "
												/>
											</div>
										)}
									</div>
								);
							})
						) : (
							<div
								className=" h-32 w-32 relative border overflow-hidden rounded-md group"
								aria-hidden="true">
								<PhotoIcon className="w-32 h-32 text-gray-500" />
							</div>
						)}

						{dataAssurance?.imagePreview?.length <= 2 ? (
							<div className="ml-5 rounded-md shadow-sm">
								<div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
									<label
										htmlFor="image-de-couverture"
										className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700">
										<span>Ajouter une image</span>
										<span className="sr-only">
											image de couverture
										</span>
									</label>
									<input
										onChange={HandleChange}
										id="image-de-couverture"
										name="images"
										type="file"
										className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div className="col-span-3 sm:col-span-2 gap-x-4 flex justify-end items-center ">
				<Button
					type="button"
					children="Suivant"
					button="default"
					onClick={() => setSelectedIndex(1)}
				/>
			</div>
		</div>
	);
};

export default Description;
