/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description chat content
 * @component ScrollableChat
 */
import React, { useEffect } from "react";
import { useGetMessagesQuery } from "../../../../features/api/apiSlice";
import Message from "./Message";
import Spinner from "../../../../UI/spinner/Spinner";

const ScrollableChat = ({ selectedChat, messageListRef }) => {
  // get message request
  const {
    data: messages,
    isLoading: messagesIsLoading,
    isSuccess: messageIsSucces,
    currentData: messagesCurrentData,
  } = useGetMessagesQuery(selectedChat.conversation_id);

  useEffect(() => {
    if (messageIsSucces || messagesCurrentData) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }

    return () => {};
  }, [messageIsSucces, messageListRef, messagesCurrentData]);

  return (
    <div
      className="px-4 pt-8 flex flex-col space-y-4 overflow-y-auto flex-1 "
      ref={messageListRef}
    >
      {messageIsSucces &&
        messages.data.map((item, index) => <Message {...item} key={index} />)}

      {messagesIsLoading && <Spinner />}
    </div>
  );
};

export default ScrollableChat;
