/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description navigation componenet of sidebar
 * @component Nav
 */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Nav = ({ userNavigation }) => {
	// url path name
	const { pathname } = useLocation();

	return userNavigation.map((item) =>
		!item.children ? (
			// single nav link
			<div key={item.name}>
				<NavLink
					to={item.to}
					className={classNames(
						item.to === pathname
							? "bg-secondary-800 text-white"
							: " text-secondary-100 hover:bg-secondary-600 hover:text-white",
						"group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-secondary-300 active:bg-secondary-800 active:text-white",
						({ isActive }) =>
							isActive ? "bg-secondary-800 text-white" : ""
					)}
					end>
					<item.icon
						className={classNames(
							item.current
								? "text-secondary-300"
								: "text-secondary-200 group-hover:text-secondary-200",
							"mr-3 flex-shrink-0 h-6 w-6"
						)}
						aria-hidden="true"
					/>
					{item.name}
				</NavLink>
				
			</div>
		) : (
			// nav link with closure
			<Disclosure
				as="div"
				key={item.name}
				className="space-y-1">
				{({ open, close }) => (
					<>
						
						<Disclosure.Button
							className={classNames(
								item.to === `${pathname}`
									? "bg-secondary-800 text-white"
									: " text-secondary-100 hover:bg-secondary-600 hover:text-white",
								"group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-secondary-300 active:bg-secondary-800 active:text-white"
							)}>
							<item.icon
								className="mr-3 h-6 w-6 flex-shrink-0 text-secondary-200 group-hover:text-secondary-200"
								aria-hidden="true"
							/>
							<span className="flex-1">{item.name}</span>
							{/* caret  */}
							<svg
								className={classNames(
									open
										? "text-secondary-400 rotate-90"
										: "text-secondary-300",
									"ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-secondary-300"
								)}
								viewBox="0 0 20 20"
								aria-hidden="true">
								<path
									d="M6 6L14 10L6 14V6Z"
									fill="currentColor"
								/>
							</svg>
						</Disclosure.Button>
						<Disclosure.Panel className="space-y-1">
							{item.children.map((subItem, index) => (
								<NavLink
									key={index}
									to={subItem.to}>
									<NavLink
										key={subItem.name}
										to={subItem.to}
										className=" group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-secondary-100 hover:bg-secondary-600 hover:text-white active:text-white focus:outline-none focus:ring-2 focus:ring-secondary-300  active:bg-secondary-800">
										{subItem.name}
									</NavLink>
								</NavLink>
							))}
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		)
	);
};

export default Nav;
