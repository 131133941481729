/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description condition step for insurance souscription
 * @component ConditionStep
 */
import React, { useState } from "react";
import Button from "../../../../../UI/button/Button";

const ConditionStep = ({ setStep, steps }) => {
	// accept state
	const [accept, setAccept] = useState(false);

	// accept terms and conditions
	const acceptHandleClick = (e) => {
		setAccept(e.target.checked);
	};

	return (
		<>

			<div className="my-4 flex items-center justify-between">
				<Button
					children="Etape précédente"
					type="button"
					onClick={() => setStep(steps[1], 1)}
				/>

			</div>
			<h2 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
				Prennez connaissance des conditions d'utilisation
			</h2>

			<div className="border rounded-md shadow-sm mb-8">
				<div className="border text-justify p-4 text-sm text-gray-700 h-[500px] overflow-auto ">
					Article 1 : Objet Les présentes CGU ou Conditions Générales
					d’Utilisation encadrent juridiquement l’utilisation des
					services du site lapetiteperle (ci-après dénommé « le site
					»). Constituant le contrat entre la société La Petite Perle,
					l’Utilisateur, l’accès au site doit être précédé de
					l’acceptation de ces CGU. L’accès à cette plateforme
					signifie l’acceptation des présentes CGU. Article 2 :
					Mentions légales L’édition du site lapetiteperle est assurée
					par la société La Petite Perle inscrite au RCS sous le
					numéro 451 432 228, dont le siège social est localisé au 3
					Rue Docteur Joubert, 59110, La Madeleine, France
					Métropolitaine. L’hébergeur du site lapetiteperle.fr est la
					société LHW, sise au 5 Rue Keller, 59100 Roubaix, France.
					Article 3 : Accès au site Le site lapetiteperle permet
					d’accéder gratuitement aux services suivants : Vente de
					bijoux (en or, en argent…) ; Location de bijoux (en or, en
					argent…) ; Dépôt-vente de bijoux. Le site est accessible
					gratuitement depuis n’importe où par tout utilisateur
					disposant d’un accès à Internet. Tous les frais nécessaires
					pour l’accès aux services (matériel informatique, connexion
					Internet…) sont à la charge de l’utilisateur. L’accès aux
					services dédiés aux membres s’effectue à l’aide d’un
					identifiant et d’un mot de passe. Pour des raisons de
					maintenance ou autres, l’accès au site peut être interrompu
					ou suspendu par l’éditeur sans préavis ni justification.
					Article 4 : Collecte des données Pour la création du compte
					de l’Utilisateur, la collecte des informations au moment de
					l’inscription sur le site est nécessaire et obligatoire.
					Conformément à la loi n°78-17 du 6 janvier relative à
					l’informatique, aux fichiers et aux libertés, la collecte et
					le traitement d’informations personnelles s’effectuent dans
					le respect de la vie privée. Suivant la loi Informatique et
					Libertés en date du 6 janvier 1978, articles 39 et 40,
					l’Utilisateur dispose du droit d’accéder, de rectifier, de
					supprimer et d’opposer ses données personnelles. L’exercice
					de ce droit s’effectue par : Le formulaire de contact ; Son
					espace client. Article 5 : Propriété intellectuelle Les
					marques, logos ainsi que les contenus du site lapetiteperle
					(illustrations graphiques, textes…) sont protégés par le
					Code de la propriété intellectuelle et par le droit
					d’auteur. La reproduction et la copie des contenus par
					l’Utilisateur requièrent une autorisation préalable du site.
					Dans ce cas, toute utilisation à des usages commerciaux ou à
					des fins publicitaires est proscrite. Article 6 :
					Responsabilité Bien que les informations publiées sur le
					site soient réputées fiables, le site se réserve la faculté
					d’une non-garantie de la fiabilité des sources. Les
					informations diffusées sur le site lapetiteperle sont
					présentées à titre purement informatif et sont sans valeur
					contractuelle. En dépit des mises à jour régulières, la
					responsabilité du site ne peut être engagée en cas de
					modification des dispositions administratives et juridiques
					apparaissant après la publication. Il en est de même pour
					l’utilisation et l’interprétation des informations
					communiquées sur la plateforme. Le site décline toute
					responsabilité concernant les éventuels virus pouvant
					infecter le matériel informatique de l’Utilisateur après
					l’utilisation ou l’accès à ce site. Le site ne peut être
					tenu pour responsable en cas de force majeure ou du fait
					imprévisible et insurmontable d’un tiers. La garantie totale
					de la sécurité et la confidentialité des données n’est pas
					assurée par le site. Cependant, le site s’engage à mettre en
					œuvre toutes les méthodes requises pour le faire au mieux.
					Article 7 : Liens hypertextes Le site peut être constitué de
					liens hypertextes. En cliquant sur ces derniers,
					l’Utilisateur sortira de la plateforme. Cette dernière n’a
					pas de contrôle et ne peut pas être tenue responsable du
					contenu des pages web relatives à ces liens. Article 8 :
					Cookies Lors des visites sur le site, l’installation
					automatique d’un cookie sur le logiciel de navigation de
					l’Utilisateur peut survenir. Les cookies correspondent à de
					petits fichiers déposés temporairement sur le disque dur de
					l’ordinateur de l’Utilisateur. Ces cookies sont nécessaires
					pour assurer l’accessibilité et la navigation sur le site.
					Ces fichiers ne comportent pas d’informations personnelles
					et ne peuvent pas être utilisés pour l’identification d’une
					personne. L’information présente dans les cookies est
					utilisée pour améliorer les performances de navigation sur
					le site lapetiteperle.fr. En naviguant sur le site,
					l’Utilisateur accepte les cookies. Leur désactivation peut
					s’effectuer via les paramètres du logiciel de navigation.
					Article 9 : Publication par l’Utilisateur
				</div>
				<div className=" bg-gray-50 p-2 ">
					<Button
						children="Précedent"
						type="button"
						onClick={() => setStep(steps[1], 1)}
						className="hidden"
					/>
					<div className="flex items-center justify-between gap-x-8">
						<div className="flex items-center">
							<input
								id="accept"
								name="accept"
								type="checkbox"
								onChange={acceptHandleClick}
								className="h-4 w-4 rounded border-gray-300 text-secondary-600 focus:ring-secondary-500"
							/>
							<label
								htmlFor="accept"
								className="ml-2 block text-sm text-gray-900">
								Lu et approuvé
							</label>
						</div>
						{accept ? (
							<Button
								children="Etape Suivante"
								type="button"
								onClick={() => setStep(steps[3], 3)}
							/>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default ConditionStep;
