/**
 * @author assan zidan
 * @email nsangouassanzidan@gmail.com
 * @description composant de pagination 
 * @component Pagination
 * @props next, 
	prev,
	jump,
	currentPage,
	maxPage,
	dataLength,
	beginItem,
	endItem,
 */

import { classNames } from "../../styles";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const Pagination = ({
	next,
	prev,
	jump,
	currentPage,
	maxPage,
	dataLength,
	beginItem,
	endItem,
}) => {
	const pages = Array(maxPage).fill();

	const nextHandleClick = () => {
		// aller à la page suivante
		next();
	};

	const prevHandleClick = () => {
		// aller à la page précèdente
		prev();
	};

	return (
		<div className="flex items-center justify-center bg-white  py-3 ">
			{/* sur petit ecran inferieur à 640px  */}
			{/* <div className="flex flex-1 justify-between sm:hidden">
				<span
					onClick={prevHandleClick}
					className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
					Précèdent
				</span>
				<span
					onClick={nextHandleClick}
					className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
					Suivant
				</span>
			</div> */}

			{/* sur grand ecran superieur à 640px  */}
			<div className="flex items-center justify-center">
				{/* <div>
					<p className="text-sm text-gray-700">
						Afficher{" "}
						<span className="font-medium">{beginItem}</span> à{" "}
						<span className="font-medium">{endItem}</span> sur{" "}
						<span className="font-medium">{dataLength}</span>{" "}
						resultats
					</p>
				</div> */}

				{/* pages  */}
				<div>
					<nav
						className="isolate inline-flex -space-x-px rounded-md shadow-sm divide-x "
						aria-label="Pagination">
						{/* precedent  */}
						<span
							onClick={prevHandleClick}
							className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:cursor-not-allowed"
							disabled={currentPage === 1}>
							<span className="sr-only">Précèdent</span>
							<ChevronLeftIcon
								className="h-5 w-5"
								aria-hidden="true"
							/>
						</span>

						{/* numero de page  */}
						{pages.map((item, index) => {
							return (
								<span
									aria-current="page"
									onClick={() => jump(index + 1)}
									className={classNames(
										"relative z-10 inline-flex items-center px-4 py-2 text-sm border focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer",
										index + 1 === currentPage
											? " bg-secondary-600 font-semibold text-white  focus-visible:outline-secondary-600"
											: "text-gray-400 hover:bg-gray-50 ring-gray-300  focus:z-20"
									)}>
									{index + 1}
								</span>
							);
						})}

						{/* suivant  */}
						<span
							onClick={nextHandleClick}
							disabled={currentPage === maxPage}
							className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:cursor-not-allowed">
							<span className="sr-only">Suivant</span>
							<ChevronRightIcon
								className="h-5 w-5"
								aria-hidden="true"
							/>
						</span>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
