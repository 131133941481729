/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description assurance card souscription
 * @component AssuranceCard
 */
import React, { useRef, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../../../UI/button/Button";
import { Transition, Dialog } from "@headlessui/react";

// modal
const MyDialog = ({ open, setOpen, titre, categorie, code }) => {
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();
  const HandleSubmit = (e) => {
    e.preventDefault();

    navigate(`/assurances/${code}`);
  };

  return (
    <>
      {/* modal pour l'ajout */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  onSubmit={(e) => HandleSubmit(e)}
                  as="form"
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                >
                  <div>
                    <div className=" text-center">
                      <Dialog.Title
                        as="h2"
                        className="text-lg font-medium leading-6 text-secondary-600"
                      >
                        {titre}
                      </Dialog.Title>
                      <Dialog.Description
                        as="p"
                        className="text-sm leading-6 text-gray-700"
                      >
                        {categorie}
                      </Dialog.Description>
                    </div>
                  </div>
                  <div className="mt-8">
                    <p className="text-sm text-gray-500">
                      Nous vous informons que vous êtes sur le point de démarrer
                      votre processus de souscription à notre produit. Nous tenons à
                      vous rappeler que vous pouvez enregistrer votre progression à
                      tout moment et revenir plus tard pour reprendre là où vous vous
                      êtes interrompu, sans avoir à recommencer depuis le début.
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <div className="sm:col-start-2 ">
                      <Button type="submit" children="souscrire" button="primary" />
                    </div>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const AssuranceCard = (props) => {
  const { titre, images, categorie, code, prix, id } = props;

  // modal state
  const [isOpen, setIsOpen] = useState(false);

  function handleCardClick(cardTitle) {
    console.log(`Veuillez cliquer sur souscrire pour prendre l'assurance ${cardTitle}`)
  };

  return (
    <>
      {/* Modal  */}
      {isOpen && (
        <MyDialog
          open={isOpen}
          setOpen={setIsOpen}
          titre={titre}
          categorie={categorie}
          code={code}
          id={id}
        />
      )}

      <div
        className=" group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
        onClick={() => handleCardClick(titre)}
      >
        <div className="aspect-w-2 aspect-h-2 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-40">
          <img
            src={images}
            alt="images produits"
            className="h-full w-full object-cover object-center sm:h-full sm:w-full"
          />
        </div>

        <div className="flex flex-1 flex-col space-y-2 p-4">
          <h3 className="text-lg font-medium text-gray-900 flex flex-1 flex-col justify-end">
            <span aria-hidden="true" />
            {titre}
            <small className="text-sm font-medium text-gray-600">{categorie}</small>
            <small className="text-sm font-medium text-gray-600">
              A partir de <b>{prix}frcfa</b>
            </small>
          </h3>
        </div>

        <div
          // to={`/assurances/${code}`}
          className="my-4 px-4"
        >
          <Button
            children="Souscrire maintenant"
            button="secondary"
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
    </>
  );
};

export default AssuranceCard;
