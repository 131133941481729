/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurance product of user
 * @component MesProduits
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ProduitCard from "./ProduitCard";

import { useGetAllSuscriberProductsQuery } from "../../../../../features/api/apiSlice";
import { getUserCode } from "../../../../../features/user/user.slice";

import Spinner from "../../../../../UI/spinner/Spinner";

const MesProduits = () => {
	// code user
	const code = useSelector(getUserCode);

	// get all souscribe product insurance request
	const { data, isSuccess, isLoading } =
		useGetAllSuscriberProductsQuery(code);

	let content;

	// product state
	const [mesproduits, setMesproduits] = useState([]);

	// set product insurance
	useEffect(() => {
		if (isSuccess) {
			const products = data?.data?.map((item) => {
				return {
					id: item.id_produit,
					code: item.code,
					description: item.description,
					duree: item.duree,
					images: item.images,
					titre: item.nom,
					restants: item.joursRestants,
					statut: item.statut,
					prix: parseInt(item.prix),
					fournisseur: item.fournisseur,
					services: item.listeServices,
					pieces_a_joindre: item.pieces_a_joindre,
					type_contrat: item.type_contrat,
					categorie: item.categorie,
					documents: item.documents,
				};
			});
			setMesproduits(products);
		}

		return () => {};
	}, [isSuccess, data]);

	if (isLoading) {
		// loader compnent
		content = (
			<div className="flex flex-1 justify-center">
				<Spinner className="h-10 w-10 text-primary-700 m-8 " />{" "}
			</div>
		);
	} else if (mesproduits?.length === 0) {
		content = (
			<div className="bg-white">
				<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
					<h1 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
						Mes Souscriptions{" "}
					</h1>
					<p className="text-2xl text-center text-gray-500">
						{" "}
						<i>Vous n'avez souscrit à aucun produit </i>{" "}
					</p>
				</div>
			</div>
		);
	} else if (isSuccess) {
		content = (
			<div className="bg-white">
				<div className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
					{/* header  */}
					<h1 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8">
						Mes Souscriptions{" "}
					</h1>

					{/* list product  */}
					<div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
						{mesproduits?.map((item, index) => {
							return (
								<ProduitCard
									key={index}
									duree={item.duree}
									description={item.description}
									titre={item.titre}
									restants={item.restants}
									images={item.images[0]?.uri}
									categorie={item.categorie.nom.toLowerCase()}
									id={item.id}
									code={item.code}
									prix={item.prix}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	} else {
		return;
	}

	return content;
};

export default MesProduits;
