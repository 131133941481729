// password validation
export const isPassword = (password) => {
	const regExpPassword =
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?.!@$%^&*-]).{8,32}$/;
	return regExpPassword.test(password);
};

// email validation
export const isEmail = (email) => {
	const regExpEmail = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
	return regExpEmail.test(email);
};

// verif passwords are egual
export const passwordMatchVerify = (password1, password2) => {
	return password1 === password2;
};
