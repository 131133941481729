
/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add sinistre modal
 * @component SinistreModal
 */

import React, { useEffect, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import {
	useGetAssurancesCategorieQuery,
	useGetTypeSinistresQuery,
	useAddSinistresMutation,
} from "../features/api/apiSlice";

import Spinner from "../UI/spinner/Spinner";
import Select from "../UI/form/Select";
import Input from "../UI/form/Input";
import TextArea from "../UI/form/TextArea";
import Button from "../UI/button/Button";
import Alert from "../UI/alert";

export const SinistreModal = ({ open, setOpen }) => {
	// categorie state
	const [idcategorie, setidcategorie] = useState("");

	// get categorie insurance
	const {
		data: assuranceCategories,
		isLoading: categoriesIsLoqding,
		isSuccess: categoriesIsSuccess,
	} = useGetAssurancesCategorieQuery(null, { skip: !open });

	// get sinistre type request
	const {
		data: typeSinistre,
		isLoading,
		isSuccess,
		isFetching,
	} = useGetTypeSinistresQuery();
	
	// alert state
	const [alert, setalert] = useState({
		text: "",
		error: false,
		isAlert: false,
	});

	// add sinistre request
	const [addSinistre, { isLoading: addSinistreIsLoading }] =
		useAddSinistresMutation();

	const [data, setdata] = useState({});

	//  type sinistre option state
	const [valueOption, setvalueOption] = useState([]);

	// categorie option type state
	const [valueOptionCategorie, setvalueOptionCategorie] = useState([]);

	// set type sinistre option state
	useEffect(() => {
		if (isSuccess || isFetching) {
			setvalueOption(
				typeSinistre?.data?.map((item) => {
					return {
						name: item.nom,
						id: item.typeSinistreId,
					};
				})
			);
		}
		
		return () => {};
	}, [idcategorie, isSuccess, isFetching, typeSinistre]);
	console.log(typeSinistre)
	// set categorie option type state
	useEffect(() => {
		if (categoriesIsSuccess) {
			setvalueOptionCategorie(
				assuranceCategories?.data?.map((item) => {
					return {
						name: item.nom,
						id: item.id_categorieProduit,
					};
				})
			);
		}

		return () => {};
		
	}, [categoriesIsSuccess, assuranceCategories]);
	
	// close modal
	const handleCloseModal = () => {
		setOpen(false);
	};

	// handle change categrie
	const categorieHandleChange = (e) => {
		const { value } = e.target;
		setidcategorie(value);
	};

	// handle change
	const handleChange = (e) => {
		const { name, value } = e.target;

		setdata((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	// add sinitre
	const addSinistreHandleClick = (e) => {
		e.preventDefault();

		addSinistre(data)
			.unwrap()
			.then((res) => {
				handleCloseModal();
			})
			.catch((err) => {
				console.log(err);
				setalert((prevState) => {
					return {
						...prevState,
						isAlert: true,
						error: true,
						text: err.data.message,
					};
				});
			});
	};
	
	return (
		<Transition.Root
			show={open}
			as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel
								as="form"
								onSubmit={addSinistreHandleClick}
								className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() => setOpen(false)}>
										<span className="sr-only">Close</span>
										<XMarkIcon
											className="h-6 w-6"
											aria-hidden="true"
										/>
									</button>
								</div>
								<Dialog.Title
									as="h1"
									className="text-xl font-semibold mb-2">
									Ajouter un sinistre
								</Dialog.Title>
								{/* alert  */}
								{alert.isAlert && (
									<Alert
										alert={alert}
										setalert={setalert}
									/>
								)}

								<div className=" mt-8 space-y-4">
									{/* insurance categorie  */}
									{categoriesIsLoqding ? (
										<div className="flex justify-center">
											<Spinner className="w-8 h-8 text-primary-600" />
										</div>
									) : (
										<Select
											label="Selectionner la categorie d'assurance"
											valueOption={valueOptionCategorie}
											name="categorie"
											defaultValue="default"
											disabled={false}
											required={false}
											onChange={categorieHandleChange}
										/>
									)}

									{/* sinistre type  */}
									{isSuccess && !isFetching && (
										<Select
											label="Selectionner le type de sinistres"
											valueOption={valueOption}
											name="type"
											defaultValue="default"
											disabled={false}
											required={false}
											onChange={handleChange}
										/>
									)}

									{(isFetching || isLoading) && (
										<div className="flex justify-center">
											<Spinner className="w-8 h-8 text-primary-600" />
										</div>
									)}

									{/* Libelle  */}
									<Input
										label="Libelle"
										input={{
											id: "titre",
											type: "text",
											maxLength: "250",
										}}
										name="titre"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>

									{/* product code  */}
									<Input
										label="Code Assurance "
										input={{
											id: "produitCode",
											type: "text",
											maxLength: "250",
										}}
										name="produitCode"
										disabled={false}
										required={false}
										onChange={handleChange}
									/>

									{/* description	  */}
									<TextArea
										name="description"
										label="Description"
										rows="6"
										id="description"
										required={true}
										onChange={handleChange}
									/>
								</div>
								<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
									<Button
										type="submit"
										button="primary"
										className="max-w-max sm:ml-3 			"
										children="Enregistrer"
										loading={addSinistreIsLoading}
									/>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() => setOpen(false)}>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
