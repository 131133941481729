/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance product form
 * @component Formulaire
 */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Question from "./Question";

import {
	getFormIds,
	getFormsEntities,
	resetForm,
	addQuestion,
} from "../../../../../features/form/form.slice";

import Button from "../../../../../UI/button/Button";

const Formulaire = ({ isLoading, handleSubmit, modif }) => {
	// id questions form array
	const formsIds = useSelector(getFormIds);

	// questions form
	const formsEntities = useSelector(getFormsEntities);

	const dispacth = useDispatch();

	// add question form
	const addQuestionhandle = () => {
		dispacth(addQuestion());
	};

	// set to initial form
	useEffect(() => {
		if (!modif) {
			dispacth(resetForm());
		}
		return () => {};
	}, [modif, dispacth]);

	return (
		<>
			<div className="space-y-8">
				{/* list question  */}
				{formsIds.map((id) => {
					return (
						<Question
							key={id}
							idform={id}
							formsEntities={formsEntities}
							modif={modif}
						/>
					);
				})}
			</div>

			{/* add question button  */}
			{!formsIds.length && (
				<div>
					<Button
						type="button"
						children="Ajouter une Question"
						className="max-w-max"
						onClick={addQuestionhandle}
					/>
				</div>
			)}

			<div className="flex justify-end mt-4">
				<Button
					type="submit"
					children="Enregistrer l'assurance"
					button="primary"
					loading={isLoading}
					onClick={handleSubmit}
					className="max-w-max"
				/>
			</div>
		</>
	);
};

export default Formulaire;
