import React, { useCallback, useState } from "react";
import { Link, useLocation, } from "react-router-dom";
import Pagination from "../../../../UI/pagination";
import UserDetailTransaction from "./UserDetailTransaction";
import { useGetTransactionsByUserQuery } from "../../../../features/api/apiSlice";
import Spinner from "../../../../UI/spinner/Spinner";
import usePagination from "../../../../hooks/usePagination";
import { classNames } from "../../../../styles";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
const UserTransaction = () => {

	// const redirectToPage = (code) => {
	// 	if (code) {
	// 	  return `/mes-assurances/${code}`;
	// 	} else {
	// 	  return `/all-operations/${code}`;
	// 	}
	//   };




	// location hook
	const { hash } = useLocation();
	const id_transaction = hash.substring(1);

	// transaction request
	const {
		data: transactions,
		isLoading,
		isSuccess,
	} = useGetTransactionsByUserQuery();
	const [selectedTransaction, setselectedTransaction] = useState(null);
	//data tabele
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const renderHeader = () => {
		return (
			<div className="flex justify-content-end">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</span>
			</div>
		);
	};
	const statusClassName = (statusTransaction) => {
		switch (statusTransaction) {
			case "En Cours de Traitement":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Annulée":
				return "inline-flex rounded-full bg-red-100 px-2 my-4 text-xs font-semibold leading-5 text-gray-800 whitespace-nowrap";
			case "Valide":
				return "inline-flex rounded-full bg-green-100 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";
			case "Echouée":
				return "inline-flex rounded-full bg-green-1000 px-2 my-4 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap";

			default:
				break;
		}
	};
	const detailTemplate = (rowData) => {
		return (
			<div>
				<Link
					to={`#${rowData.id_transaction}`}
					className="text-secondary-600 hover:text-secondary-900">
					detail
					<span className="sr-only">
						{
							rowData.id_transaction
						}
					</span>
				</Link>
			</div>
		)
	}
	const dateTemplet = (rowData) => {
		return (
			<div> {rowData.dateCreation} {""} {rowData.createdAt}</div>
		)
	}
	const statusItemTemplate = (rowData) => {
		return (<div className={statusClassName(rowData.statut)}>
			{rowData.statut}
		</div>
		)
	};

	const itemsPerPage = 10;


	const header = renderHeader();

	// pagination hook
	const {
		next,
		prev,
		jump,
		currentData,
		currentPage,
		maxPage,
		dataLength,
		beginItem,
		endItem,
	} = usePagination(transactions?.data, itemsPerPage);

	console.log("transaction table :", transactions?.data);
	console.log("Se composant charge bien !");

	return (
		<>
			<div className="px-4 sm:px-6 lg:px-8 mt-8">
				{/* header  */}
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-bold text-primary-900">
							Transactions
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							La liste des transactions.
						</p>
					</div>
				</div>

				{/* loader componenet */}


				{isLoading && (
					<div className="flex justify-center text-center">
						<Spinner className="h-10 w-10 text-primary-700  " />
					</div>
				)}
				{isSuccess && (
					<div className="card">
						<DataTable value={transactions?.data} paginator showGridlines selectedTransaction="checkbox" selection={selectedTransaction} onSelectionChange={(e) => setselectedTransaction(e.value)} rows={10}
							filters={filters} globalFilterFields={['nom', 'statut']} header={header}
							emptyMessage="Aucune transaction .">
							<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
							<Column header="TransactionId" field="id_transaction" style={{ minWidth: '12rem' }} />
							<Column header="Libele" field="motif" style={{ minWidth: '10rem' }} />
							<Column header="Date" sortable body={dateTemplet} style={{ minWidth: '12rem' }} />
							<Column header="Statut" sortable style={{ minWidth: '12rem' }} body={statusItemTemplate} />
							<Column header="" body={detailTemplate} style={{ minWidth: '12rem' }} />
						</DataTable>
					</div>
				)}
				{/* xxx */}

			</div>

			{/* transaction detail and reglement  */}
			{transactions?.data?.length > 0 && hash && (
				<UserDetailTransaction />
			)}
		</>
	);
};

export default UserTransaction;
