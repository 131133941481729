/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description member step for insurance souscription
 * @component ListAssurances
 */
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
	CheckIcon,
	ChevronDownIcon,
	MinusIcon,
} from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { USERCODE } from "../../../../../config/config";
import { useNavigate } from "react-router-dom";
import { useGetAllMembersQuery } from "../../../../../features/api/apiSlice";
import {
	getUserCode,
	getUserDefaultProfil,
	getUser,
} from "../../../../../features/user/user.slice";

import Spinner from "../../../../../UI/spinner/Spinner";
import Button from "../../../../../UI/button/Button";

import profile from "../../../../../assets/profile.png";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Membres({ recever, setRecever, setStep, steps }) {
	const navigate = useNavigate();
	// user profil
	const { FAMILY, ENTREPRISE } = USERCODE;

	// user code
	const code = useSelector(getUserCode);

	// user default profil
	const defaultProfil = useSelector(getUserDefaultProfil);

	// get user
	const user = useSelector(getUser);

	const [query, setQuery] = useState("");
	const [people, setPeople] = useState([]);
	const [selectedPerson, setSelectedPerson] = useState(null);
	const [selectedPeople, setSelectedPeople] = useState([]);
	const [skip, setskip] = useState(true);

	// get user member request
	const {
		data: membres,
		isLoading,
		isSuccess,
	} = useGetAllMembersQuery(code, {
		skip,
	});

	// default user
	const userMenbre = {
		cedoMenbre: code,
		nomComplet: `${user?.nom} ${user?.prenom}`,
	};

	let content;

	// set skip request
	useEffect(() => {
		if (defaultProfil?.id === FAMILY || defaultProfil?.id === ENTREPRISE) {
			setskip(false);
		} else {
			setPeople([userMenbre]);
		}

		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSuccess) {
			if (defaultProfil?.id === FAMILY) {
				setPeople((prevState) => {
					return [...prevState, userMenbre, ...membres?.data];
				});
			} else {
				setPeople(membres?.data);
			}
		}

		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, membres, defaultProfil.id]);

	const filteredPeople =
		query === ""
			? people
			: people.filter((person) => {
				return person.nomComplet
					?.toLowerCase()
					.includes(query.toLowerCase());
			});

	// add user to list
	const selectHandleChange = (value) => {
		setSelectedPerson(value);
		if (selectedPeople.length === 0) {
			setRecever((prevState) => {
				return [...prevState, value];
			});
			setSelectedPeople((prevState) => {
				return [...prevState, value];
			});
		} else {
			const isValue = selectedPeople.filter(
				(item) => item.codeMembre === value.codeMembre
			);
			if (isValue.length === 0) {
				setRecever((prevState) => {
					return [...prevState, value];
				});
				setSelectedPeople((prevState) => {
					return [...prevState, value];
				});
			} else {
				if (isValue[0].codeMembre === value.codeMembre) {
					return;
				} else {
					setRecever((prevState) => {
						return [...prevState, value];
					});
					setSelectedPeople((prevState) => {
						return [...prevState, value];
					});
				}
			}
		}
	};

	// remove user to list
	const removeItem = (url) => {
		const newTableServices = selectedPeople.filter((item) => item !== url);
		setSelectedPeople(newTableServices);
		setRecever(newTableServices);
	};

	// is benificiare
	const isBeneficiaire = recever?.length > 0;

	if (skip) {
		content = (
			<Combobox
				as="div"
				value={selectedPerson}
				onChange={selectHandleChange}
				className="mr-100"
			>
				<Combobox.Label className="block text-sm font-medium text-gray-700 ">
					Veillez saisir ou choisir le nom des bénéficiaires
				</Combobox.Label>
				<div className="relative mt-1">
					<Combobox.Input
						className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
						secondary
						onChange={(event) => setQuery(event.target.value)}
						displayValue={(person) => person?.nomComplet}
					/>
					<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						<ChevronDownIcon
							className="h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
					</Combobox.Button>

					{filteredPeople?.length > 0 && (
						<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							{filteredPeople?.map((person, index) => (
								<Combobox.Option
									key={index}
									value={person}
									className={({ active }) =>
										classNames(
											"relative cursor-default select-none py-2 pl-8 pr-4",
											active
												? "bg-secondary-600 text-white"
												: "text-gray-900"
										)
									}>
									{({ active, selected }) => (
										<>
											<span
												className={classNames(
													"block truncate",
													selected && "font-semibold"
												)}>
												{person.nomComplet}
											</span>

											{selected && (
												<span
													className={classNames(
														"absolute inset-y-0 left-0 flex items-center pl-1.5",
														active
															? "text-white"
															: "text-secondary-600"
													)}>
													<CheckIcon
														className="h-5 w-5"
														aria-hidden="true"
													/>
												</span>
											)}
										</>
									)}
								</Combobox.Option>
							))}
						</Combobox.Options>
					)}
				</div>
			</Combobox>
		);
	} else {
		if (isSuccess) {
			content = (
				<Combobox
					as="div"
					value={selectedPerson}
					onChange={selectHandleChange}>
					<Combobox.Label className="block text-sm font-medium text-gray-700">
						Veillez saisir ou choisir le nom des bénéficiaires
					</Combobox.Label>
					<div className="relative mt-1">
						<Combobox.Input
							className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-secondary-500 focus:outline-none focus:ring-1 focus:ring-secondary-500 sm:text-sm"
							secondary
							onChange={(event) => setQuery(event.target.value)}
							displayValue={(person) => person?.nomComplet}
						/>
						<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
							<ChevronDownIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</Combobox.Button>

						{/* family entreprise  */}
						{defaultProfil?.id === FAMILY &&
							filteredPeople?.length > 0 && (
								<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{filteredPeople?.map((person, index) => (
										<Combobox.Option
											key={index}
											value={person}
											className={({ active }) =>
												classNames(
													"relative cursor-default select-none py-2 pl-8 pr-4",
													active
														? "bg-secondary-600 text-white"
														: "text-gray-900"
												)
											}>
											{({ active, selected }) => (
												<>
													<span
														className={classNames(
															"block truncate",
															selected &&
															"font-semibold"
														)}>
														{person.nomComplet}
													</span>

													{selected && (
														<span
															className={classNames(
																"absolute inset-y-0 left-0 flex items-center pl-1.5",
																active
																	? "text-white"
																	: "text-secondary-600"
															)}>
															<CheckIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													)}
												</>
											)}
										</Combobox.Option>
									))}
								</Combobox.Options>
							)}

						{/* entreprise account  */}
						{defaultProfil?.id === ENTREPRISE &&
							filteredPeople.length > 0 && (
								<Combobox.Options className="absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{filteredPeople.map((person, index) => (
										<Combobox.Option
											key={index}
											value={person}
											className={({ active }) =>
												classNames(
													"relative cursor-default select-none py-2 pl-3 pr-9",
													active
														? "bg-secondary-600 text-white"
														: "text-gray-900"
												)
											}>
											{({ active, selected }) => (
												<>
													<div className="flex items-center">
														<img
															src={
																person.photo
																	.length > 0
																	? person.photo
																	: profile
															}
															alt=""
															className="h-6 w-6 flex-shrink-0 rounded-full object-cover"
														/>
														<span
															className={classNames(
																"ml-3 truncate",
																selected &&
																"font-semibold"
															)}>
															{person.nomComplet}
														</span>
													</div>

													{selected && (
														<span
															className={classNames(
																"absolute inset-y-0 right-0 flex items-center pr-4",
																active
																	? "text-white"
																	: "text-secondary-600"
															)}>
															<CheckIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													)}
												</>
											)}
										</Combobox.Option>
									))}
								</Combobox.Options>
							)}
					</div>
				</Combobox>
			);
		}
	}

	return (
		<>
			<div>
				<div className="my-4 flex items-center justify-between">
					<Button
						children="Etape précédente"
						type="button"
						onClick={() => navigate(-1)}
					/>

				</div>
				<div>
					<h2 className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-9">
						Renseignez le/les beneficiaire(s).
					</h2>

					<div className="max-w-lg mx-auto">
						{content}

						{/* loader spiner  */}
						{isLoading && (
							<Spinner className="mt-8 w-10 h-10 m-auto text-primary-700" />
						)}

						{/* list user  */}
						<div className="bg-gray-50 p-4 mt-8 rounded-md">
							<h3 className="text-gray-700">Mes bénéficiaires</h3>
							<ul className="mt-4 divide-y-2">
								{selectedPeople?.map((item, index) => {
									return (
										<li
											className="flex items-center justify-between py-2"
											key={index}>
											<span>{item.nomComplet}</span>{" "}
											<MinusIcon
												className="w-4 h-4 cursor-pointer"
												onClick={() => removeItem(item)}
											/>
										</li>
									);
								})}
							</ul>
						</div>

						<div className="mt-8 flex items-center justify-between">
							<Button
								children="Etape précédente"
								type="button"
								className="hidden"
								onClick={() => setStep(steps[0], 0)}
							/>
							<Button
								children="Etape Suivante"
								type="button"
								disabled={!isBeneficiaire}
								onClick={() => setStep(steps[2], 2)}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
