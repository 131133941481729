import { useParams } from "react-router-dom";
import { useGetAssuranceByCodeQuery } from "../../../../../features/api/apiSlice";
import { Fragment, useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import Description from "./Description";
import Formulaire from "./Formulaire";
import Services from "./Services";
import Document from "./Document";
import {
	getFormsEntities,
	initialForm,
	resetForm,
} from "../../../../../features/form/form.slice";

import {
	MapPinIcon,
	CalendarDaysIcon,
	BanknotesIcon,
	BellIcon,
} from "@heroicons/react/20/solid";
import Spinner from "../../../../../UI/spinner/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { useSetProductAssuranceMutation } from "../../../../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { setModal } from "../../../../../features/feedBack/modal.slice";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

// tab data
const tabs = [
	{
		title: "Description",
	},
	{
		title: "Services et pieces à joindres",
	},
	{
		title: "Documents associés",
	},
	{
		title: "Formulaire associé",
	},
];

const SetAssurance = () => {
	const navigate = useNavigate();

	const goBack = () => navigate(-1);
	const { code } = useParams();
	const dispatch = useDispatch();

	const dispatchForm = useDispatch();

	// get insurance request
	const {
		data,
		isError,
		isSuccess,
		isLoading: loading,
	} = useGetAssuranceByCodeQuery(code);

	// get question form
	const formsEntities = useSelector(getFormsEntities);

	//initial product state
	const [dataAssurance, SetDataAssurance] = useState({
		document: {
			document1: {
				id: 1,
				title: "",
				file: "",
				old: false,
				preview: undefined,
			},
		},
		services: [],
		pieces: [],
		imagePreview: [],
		images: [],
	});

	// destructuration
	const {
		services,
		pieces,
		nom,
		prix,
		duree,
		images,
		document,
		description,
		fournisseur,
		categorie,
		
	} = dataAssurance;

	useEffect(() => {
		if (isSuccess && !isError && !loading) {
			// set default form entities request
			const setFormEntities = data?.data?.form?.reduce(
				(obj, item, index) => {
					return {
						...obj,
						[item.id_question]: {
							id: item.id_question,
							isActive: false,
							isRequired: item.requis,
							name: item.question,
							tarifType: item.tarifType,
							type: item.fieldType,
							options: item.options,
							actionForm: item.actionForm,
						},
					};
				},
				{}
			);

			// set default form request
			const formulaire = {
				ids: data?.data?.form?.map((item) => item.id_question),
				entities: setFormEntities,
			};

			// dispatch formulaire state
			dispatchForm(initialForm(formulaire));

			// set default document request
			const setDocument = data?.data?.documents?.reduce(
				(obj, item, index) => {
					return {
						...obj,
						[`document${item.id}`]: {
							id: item.id,
							title: item.nom,
							file: "",
							old: true,
							preview: item.uri,
						},
					};
				},
				{}
			);

			// set default images request
			const setImgPreview = data?.data?.images?.map((item) => {
				return {
					...item,
					old: true,
				};
			});

			// set default services request
			const setlisteServices = data?.data?.listeServices?.map((item) => {
				return {
					value: item.nom,
					description: item.description,
					label: item.nom,
					id: item.id,
				};
			});

			// set default services request
			const setlistePieces = data?.data?.pieces_a_joindre?.map((item) => {
				return {
					value: item.nomPiece,
					description: item.description,
					label: item.nomPiece,
					id: item.id,
				};
			});

			if (data?.data?.pieces_a_joindre) {
				SetDataAssurance((prevState) => {
					return {
						...prevState,
						pieces: setlistePieces,
					};
				});
			}

			// set default dataAssurrance request
			SetDataAssurance((prevState) => {
				return {
					...prevState,
					nom: data?.data?.nom,
					description: data?.data?.description,
					fournisseur: data?.data?.fournisseur,
					prix: parseInt(data?.data?.prix),
					duree: parseInt(data?.data?.duree),
					imagePreview: setImgPreview,
					services: setlisteServices,
					document: setDocument,
					categorie: data?.data?.categorie.id,
				};
			});
		}

		return () => {};
	}, [isSuccess, data, dispatchForm, isError, loading]);
	// end side effect

	const documentArray = Object.values(document).filter(
		(item) => item.old !== true
	);

	const formDate = new FormData();

	// code add cette fonction ajoute les object à form data
	function getFormData(item, index, name) {
		const formTransForm = Object.keys(item).map((key) => {
			if (key === "options") {
				const options = item[key].map((option, indexOption) => {
					return Object.keys(option).map((keyOption) =>
						formDate.append(
							`${name}[${index}][${key}][${indexOption}][${keyOption}]`,
							option[keyOption]
						)
					);
				});

				return options;
			} else {
				return formDate.append(`${name}[${index}][${key}]`, item[key]);
			}
		});

		return formTransForm;
	}
	// end

	const pushDataformulaire = (array, name) => {
		const newformData = array.map((form, index) => {
			return getFormData(form, index, name);
		});

		return newformData;
	};

	// transform data to formData with blob object
	const pushTableform = (array, key, object) => {
		if (object) {
			if (array?.length) {
				const documentName = array.map((item, index) => {
					return formDate.append(`${key}[${index}]`, item.title);
				});

				const documentFile = array.map((item, index) => {
					return formDate.append(
						`${key}[${index}]`,
						item.file,
						`file${index}.pdf`
					);
				});

				return [documentName, documentFile];
			} else {
				return [];
			}
		} else {
			if (array?.length) {
				return array.map((item, index) => {
					return formDate.append(`${key}[${index}]`, item);
				});
			} else {
				return [];
			}
		}
	};

	// add data to formdata
	const pushItemform = (item, key) => {
		return formDate.append(`${key}`, item);
	};

	// services array
	const serviceID = services.map((item) => {
		return item.id;
	});

	// pieces array
	const piecesName = pieces.map((item) => {
		return item.id;
	});

	// get form question
	const entities = Object.values(formsEntities);

	const form = entities.map((form) => {
		return {
			options: form.options,
			isRequired: form.isRequired,
			name: form.name,
			tarifType: form.tarifType,
			type: form.type,
			id_question: form.id,
			actionForm: form.actionForm,
		};
	});


	// set insurance
	const [setInsuranceProduct, { isLoading }] =
		useSetProductAssuranceMutation();

	const handleSubmit = async (e) => {
		e.preventDefault();
		pushTableform(images, "images");
		pushTableform(documentArray, "documents", true);
		pushTableform(serviceID, "services");
		pushTableform(piecesName, "pieces");
		pushDataformulaire(form, "form");
		pushItemform(nom, "nom");
		pushItemform(description, "description");
		pushItemform(prix, "prix");
		pushItemform(fournisseur, "fournisseur");
		pushItemform(duree, "duree");
		pushItemform(categorie, "categorie");

		setInsuranceProduct({ formDate, code })
			.unwrap()
			.then((data) => {
				
				dispatchForm(resetForm());
				navigate("/mes-assurances");
			})
			.catch((err) => {
				console.error("Failed to save the assur: ", err);
			});
	};

	// tab index state
	const [selectedIndex, setSelectedIndex] = useState(0);

	return (
		<>
			{loading && (
				<Spinner className="w-10 h-10 m-auto text-primary-600" />
			)}
			{isSuccess && data && (
				<Tab.Group
					as={"form"}
					className="space-y-8 mt-8"
					onSubmit={handleSubmit}
					selectedIndex={selectedIndex}
					onChange={setSelectedIndex}>
		
						<div className="py-6 mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
							<div className="px-4 py-5 sm:px-6">

								<button
									className=" rounded-md flex justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed  undefined"
									onClick={goBack}>
									Retour
								</button>
							</div>
							<div className="border-t border-gray-200  py-5 ">
								<div className="px-4 py-5 sm:px-6">
									<h3 className="text-lg font-medium leading-6 text-gray-900 flex flex-col justify-start">
										
										Details de l'assurance
									</h3>
								</div>
								
								<div className="border-t border-gray-200  py-5  ">
									<div className="pt-5 px-4 sm:px-6">
										<div className="grid grid-cols-1 gap-x-4 gap-y-8  sm:grid-cols-2">
											<div className="sm:col-span-1">
												<div className="text-sm font-medium  text-gray-500">
													Identifiant de l'assurance:{" "}
													<span className="text-md ml-4 text-gray-900">
														{code}
													</span>
												</div>
												<div className="text-sm font-medium mt-3 text-gray-500">
													Nom de l'assurance:{" "}
													<span className="text-md ml-4 text-gray-900">
														{`${nom} `}
													</span>
												</div>
											</div>
											<div className="sm:col-span-1">
												<div className="text-sm font-medium inline-flex text-gray-500">
													{/* <MapPinIcon
														className="h-5 w-5 text-green-400"
														aria-hidden="true"
													/> */}
													Nom du fournisseur:
												</div>
												<dd className="mt-1 text-sm text-gray-900">{fournisseur}</dd> 
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium inline-flex text-gray-500">
													<BellIcon
														className="h-5 w-5 text-red-400"
														aria-hidden="true"
													/>
													Description:<dd className="mt-0 text-md text-gray-900">{description}</dd> 
												</dt> 

												
												
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium inline-flex text-gray-500">
													{/* <ArticleIcon
														className="h-5 w-5 text-red-400"
														aria-hidden="true"
													/> */}
													Identifiant de la catégorie: <span className="text-md ml-4 text-gray-900">
													{categorie}
													</span>
												</dt>
												
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium inline-flex text-gray-500">
													<BanknotesIcon
														className="h-5 w-5 text-yellow-400"
														aria-hidden="true"
													/>
													Prix initial de l'assurance:
												</dt>
												<dd className="mt-1 text-lg text-gray-900">
													{prix} XAF{" "}
													{/* <span className="text-sm">
														{isAssur && "(Montant couvert par votre assurance)"}
													</span> */}
												</dd>
											</div>
											<div className="sm:col-span-1">
												<div className="text-sm font-medium  text-gray-500">
													Durée:{" "}
													<span className="text-md ml-4 text-gray-900">
														{duree}  jour
													</span>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>

						</div>	
						
						

					

				</Tab.Group>
			)}
		</> 
	);
};

export default SetAssurance;
