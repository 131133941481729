/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description chat page
 * @component EchangePage
 */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { socket } from "../socket.js";

import Chats from "../components/layouts/main/discusssion/Chats";
import { IncidentModal } from "../components/layouts/main/discusssion/IncidentModal";
import { SinistreModal } from "../components/layouts/main/discusssion/SinistreModal";
import SingleChat from "../components/layouts/main/discusssion/SingleChat";
import WelcomeChat from "../components/layouts/main/discusssion/WelcomeChat";
import NewChatGroupModal from "../components/layouts/main/discusssion/NewChatGroupModal.js";
import NewChatModal from "../components/layouts/main/discusssion/NewChatModal.js";

import { getUser } from "../features/user/user.slice";
import { getSelectedChat } from "../features/chats/chats.slice";
import { setOnline } from "../features/user/isOnlineStatus.slice.js";

const EchangePage = () => {
  const dispatch = useDispatch();

  const selectedChat = useSelector(getSelectedChat);

  // user
  const user = useSelector(getUser);

  // open incident modal state
  const [openIncidentModal, setopenIncidentModal] = useState(false);

  // open sinistre modal state
  const [openSinistreModal, setopenSinistreModal] = useState(false);

  // open chat modal state
  const [openNewChat, setopenNewChat] = useState(false);

  // open chat group modal state
  const [openNewChatGroup, setopenNewChatGroup] = useState(false);

  // initialisation du socket

  useEffect(() => {
    // create socket connection
    socket.connect();

    const onConnect = () => {
      // add user user socket
      socket.emit("setup", user);
    };

    const onUserSetup = (isOnline) => {
      // add user user socket
      dispatch(setOnline(isOnline));
    };

    // on connect
    socket.on("connect", onConnect);

    // connecter success
    socket.on("connected", onUserSetup);

    return () => {
      //   socket.off("connect", onConnect);
      //   socket.off("connected", onUserSetup);
    };
  }, [user, dispatch]);

  useEffect(() => {
    // join chat
    if (selectedChat) {
      socket.emit("join chat", selectedChat?.conversation_id);
    }

    return () => {};
  }, [selectedChat]);

  return (
    <>
      <div className="flex justify-between max-h-[calc(100vh_-_4rem)]  h-[calc(100vh_-_4rem)]">
        {/* chats list  */}
        <Chats
          setopenSinistreModal={setopenSinistreModal}
          setopenIncidentModal={setopenIncidentModal}
          setopenNewChatGroup={setopenNewChatGroup}
          setopenNewChat={setopenNewChat}
        />

        {/* selected chat  */}
        {selectedChat ? <SingleChat /> : <WelcomeChat />}
      </div>

      {/* add incident modal  */}
      {openIncidentModal && (
        <IncidentModal
          openIncidentModal={openIncidentModal}
          setopenIncidentModal={setopenIncidentModal}
        />
      )}

      {/* add sinistre modal  */}
      {openSinistreModal && (
        <SinistreModal
          openSinistreModal={openSinistreModal}
          setopenSinistreModal={setopenSinistreModal}
        />
      )}

      {/* add chat group modal  */}
      {openNewChatGroup && (
        <NewChatGroupModal
          setopenNewChatGroup={setopenNewChatGroup}
          openNewChatGroup={openNewChatGroup}
        />
      )}

      {/* add chat modal  */}
      {openNewChat && (
        <NewChatModal setopenNewChat={setopenNewChat} openNewChat={openNewChat} />
      )}
    </>
  );
};

export default EchangePage;
