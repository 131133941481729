/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description add insurance page
 * @component AddAssurancePage
 */

import React from "react";
import AddAssurance from "../../../components/layouts/main/assurances/provider/AddAssurance";

const AddAssurancePage = () => {
	return <AddAssurance />;
};

export default AddAssurancePage;
