/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description question card
 * @component Question
 */
import React, { useCallback } from "react";
import SwitchInput from "../../../../../UI/form/SwitchInput";
import { HiOutlineTrash, PlusIcon } from "../../../../../UI/svg";
import SelectLabel from "./SelectLabel";
import SelectPriceModification from "./SelectPriceModification";
import { useDispatch } from "react-redux";
import {
	addQuestion,
	deleteQuestion,
} from "../../../../../features/form/form.slice";

const Question = ({ idform, formsEntities, modif }) => {
	const dispatch = useDispatch();

	// add form question
	const addQuestionHandle = useCallback(() => {
		dispatch(addQuestion());
	}, [dispatch]);

	// delete form question
	const deleteFormHandle = useCallback(
		(id) => {
			if (modif) {
				// dispatch()
			} else {
				dispatch(deleteQuestion(id));
			}
		},
		[dispatch, modif]
	);

	return (
		<div className="grid grid-cols-4 gap-6 p-0 ">
			<div className="col-span-4 lg:col-span-4 border border-gray-50 shadow-md border-l-4 border-l-secondary-500 rounded md:p-4 p-2 divide-y divide-gray-300">
				{/* selected question type  */}
				<SelectLabel
					idform={idform}
					formsEntities={formsEntities}
				/>

				<div className="flex justify-between items-start sm:items-center pt-4 sm:flex-row flex-col  ">
					<div className="font-medium text-sm flex items-center text-gray-700">
						<span>Modifier le prix initial par:</span>{" "}
						<div>
							{/* price  */}
							<SelectPriceModification
								idform={idform}
								formsEntities={formsEntities}
							/>
						</div>{" "}
					</div>
					<div className="flex  items-center md:divide-x divide-gray-300">
						<div className="flex items-center  justify-between mr-4 mt-6 sm:mt-0">
							{/* delete question  */}
							<HiOutlineTrash
								className="h-6 w-6 mx-2 text-gray-500 hover:text-gray-700 cursor-pointer"
								onClick={deleteFormHandle.bind(null, idform)}
							/>
							{/* add question  */}
							<PlusIcon
								className="h-6 w-6 mx-2 text-gray-500 hover:text-gray-700 cursor-pointer"
								onClick={addQuestionHandle.bind(null)}
							/>
						</div>
						{/* is required  */}
						<SwitchInput
							idform={idform}
							formsEntities={formsEntities}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Question;
