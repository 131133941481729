/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description formulaire d'inscription
 * @component ResgistrationForm
 * @props  setalert, 
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sha256 from "sha256"

import Input from '../../UI/form/Input'
import Button from '../../UI/button/Button';
import Select from '../../UI/form/Select';

import usePasswordToggle from '../../hooks/usePasswordToggle';
import useInput from "../../hooks/use-input"
import useSelect from "../../hooks/use-select"

import { isPassword, isEmail } from "../../lib/inputValidation"

import axiosInstance from '../../apis/inchAssur'

const ResgistrationForm = (props) => {
  // props 
  const { setalert } = props

  const [loading, setloading] = useState(false)

  const navigate = useNavigate()

  const [passwordType, Eye] = usePasswordToggle()
  const [passwordType2, Eye2] = usePasswordToggle()

  // categry data
  const categorie = [{ id: "IA1", name: "Particulier" }, { id: "IA2", name: "Famille" }, { id: "IA3", name: "Entreprise" }]

  // lastName control
  const {
    value: enteredNom,
    isValid: enteredNomIsValid,
    hasError: nomInputHasError,
    valueChangeHandler: nomHandleChange,
    inputBlurHandler: nomHandleBlur,
  } = useInput(value => value.trim() !== '')

  // firstname control
  const {
    value: enteredPrenom,
    isValid: enteredPrenomIsValid,
    hasError: prenomInputHasError,
    valueChangeHandler: prenomHandleChange,
    inputBlurHandler: prenomHandleBlur,
  } = useInput(value => value.trim() !== '')

  //control email
  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailHandleChange,
    inputBlurHandler: emailHandleBlur,
  } = useInput(isEmail)

  // phone control
  const {
    value: enteredPhone,
    isValid: enterdPhoneIsValid,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneHandleChange,
    inputBlurHandler: phoneHandleBlur,
  } = useInput(value => value.trim() !== '' && value.trim().length === 9)

  // pasword control
  const {
    value: enteredpassword,
    isValid: enteredpasswordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordHandleChange,
    inputBlurHandler: passwordHandleBlur,
  } = useInput(isPassword)

  // password verif control
  const {
    value: enteredpasswordVerified,
    isValid: passwordVerifiedIsValid,
    hasError: passwordVerifiedInputHasError,
    valueChangeHandler: passwordVerifiedHandleChange,
    inputBlurHandler: passwordVerifiedhandleBlur,
  } = useInput(value => value.trim() !== '')

  // select control
  const {
    validSelect: categorieIsValid,
    value: categorieSelected,
    selectBlurHandler: categorieHandleBlur,
    valueChangeHandler: categorieHandleChange,
  } = useSelect()

  // termsIsValid state
  const [termsIsValid, settermsIsValid] = useState(false)

  // password is equal
  const isPasswordIsEqual = (enteredpassword === enteredpasswordVerified)

  // accept terms and conditions
  const termsHandleClick = (e) => {
    settermsIsValid(e.target.checked)
  }

  // validate form 
  const formIsValid = [enteredEmailIsValid, enteredNomIsValid, passwordVerifiedIsValid, enteredpasswordIsValid, enterdPhoneIsValid, isPasswordIsEqual, categorieIsValid, enteredPrenomIsValid, termsIsValid].every(currentValue => currentValue === true)


  // request 
  const registerHAndler = (e) => {
    e.preventDefault()
    const data = {
      nom: enteredNom,
      prenom: enteredPrenom,
      email: enteredEmail,
      tel1: enteredPhone,
      categorie: categorieSelected,
      password: sha256(enteredpassword)
    }

    setloading(true)
    axiosInstance.post('/account', {
      ...data,
    }).then((res) => {
      console.log(res.data);
      setalert(prevState => {
        return {
          ...prevState,
          isAlert: true,
          error: false,
          text: res.data.message,
        }
      })
      // delete stokage 
      // localStorage.setItem('token', res.data.token)
      navigate(`/validation/${res.data.token}`)
    }).catch((err) => {
      if (err.response.status === 500) {
        setalert(prevState => {
          return {
            ...prevState,
            isAlert: true,
            error: true,
            text: "Une erreur s'est produite. Veillez reéssayer ",
          }
        })
      }
      console.log(err.response.status);
      console.log(err.response.data);
      // set error 500 
      setalert(prevState => {
        return {
          ...prevState,
          isAlert: true,
          error: true,
          text: "Addresse mail ou numero de téléphone existant",
        }
      })
    }).finally(() => setloading(false))

    // if (response && error) {
    //   switch (response.statut) {
    //     case "ok":
    //       setalert( prevState => {
    //         return {
    //           ...prevState,
    //           error: true,
    //           text: response.message,
    //           status: "success",
    //         }
    //       })
    //       navigate(`/auth/validation/${response.token}`)
    //       break;
    //     case "no":
    //       setalert( prevState => {
    //         return {
    //           ...prevState,
    //           error,
    //           text: "Addresse mail ou numero de téléphine existant",
    //           status: "error",
    //         }
    //       })
    //       break;

    //     default:
    //       break;
    //   }
    // }
  }


  return (
    <form onSubmit={registerHAndler} className="space-y-6">
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <Input label="Nom" inputError={nomInputHasError} placeholder={"entrer votre nom"} onChange={nomHandleChange} onBlur={nomHandleBlur} input={{ id: "nom", type: "text", maxLength: "80" }} value={enteredNom} name={"nom"} disabled={false} required={true} errorText={"le champs est incorrect"} ariaInputError="nom" />

        <Input label="Prénom" inputError={prenomInputHasError} onChange={prenomHandleChange} onBlur={prenomHandleBlur} placeholder={"entrer votre prénom "} value={enteredPrenom} input={{ id: "prenom", type: "text", maxLength: "80" }} errorText={"le champs est incorrect"} name={"prenom"} disabled={false} required={true} ariaInputError="prenom" />
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <Input label="Addresse mail" inputError={emailInputHasError} placeholder={"entrer votre addresse mail"} value={enteredEmail} onChange={emailHandleChange} onBlur={emailHandleBlur} input={{ id: "email", type: "email", maxLength: "80" }} name={"email"} disabled={false} required={true} errorText={"Addresse mail incorect"} ariaInputError="email" />

        <Input label="Numéro de telephone"
          icon="+237" value={enteredPhone} onChange={phoneHandleChange} onBlur={phoneHandleBlur} inputError={phoneInputHasError} input={{ id: "phone", type: "tel", maxLength: "9", minLength: "9" }} name={"phone"} disabled={false} required={true} ariaInputError="phone" errorText={"Numéro de téléphone incorrect"} />
      </div>

      <Select
        valueOption={categorie} label="Catégorie"
        defaultValue="default"
        id="categorie" name="categorie"
        onChange={categorieHandleChange}
        onBlur={categorieHandleBlur}
      />

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <Input label="Mot de passe" inputError={passwordInputHasError} value={enteredpassword} onChange={passwordHandleChange} onBlur={passwordHandleBlur} placeholder={"entrer votre mot de passe"} input={{ id: "password", type: passwordType }} Eye={Eye} name={"password"} disabled={false} required={true} errorText="le mot de passe doit contenir au moins une majuscule, une miniscule, un chiffre, ou un caractere speciale #, ?, !, @, $, %, ^, &, *, -, :, ;, <, >... " ariaInputError="password" />

        <Input label="Vérification de mot de passe" inputError={(!isPasswordIsEqual && enteredpasswordVerified.length >= 1) || passwordVerifiedInputHasError} onChange={passwordVerifiedHandleChange} onBlur={passwordVerifiedhandleBlur} placeholder={"entrer votre mot de passe"} input={{ id: "passwordVerif", type: passwordType2 }} Eye={Eye2} value={enteredpasswordVerified} errorText={"les mots de passe ne corresondent pas"} name={"passwordVerif"} disabled={false} required={true} ariaInputError="passwordVerif" />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-start">
          <input
            onChange={termsHandleClick}
            id="terms"
            name="terms"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-secondary-600 focus:ring-secondary-500"
          />
          <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
            J'ai lu et j'accepte les conditions d'utilisations et la politique de confidentialité
          </label>
        </div>
      </div>

      <div>
        <Button type="submit" children="S'inscrire" button="primary" loading={loading} error={!formIsValid} />
      </div>
    </form>
  )
}

export default ResgistrationForm