/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user transaction page
 * @component UserTransactionPage
 */

import Main from "../../components/layouts/main";

import UserTransaction from "../../components/layouts/main/user/UserTransaction";

const UserTransactionPage = () => {
	return <Main children={<UserTransaction />} />;
};

export default UserTransactionPage;
