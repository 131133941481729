/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description   transaction detail
 * @component TransactionDetail
 */
import React from "react";
import { useLocation } from "react-router-dom";

import { Link, useNavigate } from "react-router-dom";
import { useGetTransactionsDetailQuery } from "../../../../features/api/apiSlice";
import Button from "../../../../UI/button/Button";

import Spinner from "../../../../UI/spinner/Spinner";

const TransactionDetail = () => {
	// location hook
	const { hash } = useLocation();
	const id_transaction = hash.substring(1);

	// get reglement request
	const {
		data: reglements,
		isLoading,
		isSuccess,
	} = useGetTransactionsDetailQuery({ id_transaction });
	
	const navigate = useNavigate();

	return (
		<>
			{/* loader component  */}
			{isLoading && (
				<div className="flex justify-center text-center">
					<Spinner className="h-10 w-10 text-primary-700  " />
				</div>
			)}

			{isSuccess && (
				<div className="-mx-4 mt-8 flow-root sm:mx-0">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
									Produit
								</th>
								<th
									scope="col"
									className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Quantité
								</th>
								<th
									scope="col"
									className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Prix unitaire
								</th>
								<th
									scope="col"
									className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
									Prix
								</th>
							</tr>
						</thead>
						<tbody>
							{reglements?.data?.map((product, index) => {
								// console.log("data value :", product)
								const urlPath = product?.details_produit?.code
								? `Consultations/${product?.details_produit?.code}`
								: `mes-assurances/${product?.details_produit?.codeProduit}`;

								return (
									<tr
										key={product.id}
										className="border-b border-gray-200">
										<td className="py-4 pl-4 pr-3 text-sm sm:pl-0">
											<div className="font-medium text-gray-900">
												{product.details_produit.produit}
											</div>
											<div className="mt-0.5 text-gray-500 sm:hidden">
												{product.quantite} hours at{" "}
												{product.prixUnitaire}
											</div>
										</td>
										<td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
											{product.quantite}
										</td>
										<td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
											{product.prixUnitaire}
										</td>
										<td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
											{product.prixTotal}
										</td>
										<div className="bg-gray-5 px-4 py-3 text-right sm:px-6 flex justify-end">
											<Button
												button="primary"
												type="submit"
												children="Detail"
												onClick={() =>
													navigate({
														pathname: `${urlPath}`,
														state: product,
													})
												}
												className="max-w-max"
											/>
										</div>
									</tr>
								);
								
							})}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};

export default TransactionDetail;
