/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description list insurance of insurer page
 * @component ListAssurancesOwnerPages
 */

import React from "react";
import ListAssurancesOwner from "../../../components/layouts/main/assurances/provider/ListAssurancesOwner";

const ListAssurancesOwnerPages = () => {
	return <ListAssurancesOwner />;
};

export default ListAssurancesOwnerPages;
