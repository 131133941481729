/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @component InputTextQuestion
 * @description composant qui genere les champs de formulaire de type radio ou checkbox personnaliser
 * @props Icon, idform, formsEntities
 * @
 */

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";

import { setQuestionForm } from "../../features/form/form.slice";
import { XMarkIcon } from "../svg";

const RadioQuestion = ({ Icon, idform, formsEntities }) => {
	const dispatchQuestionRadioOrCheckbox = useDispatch();

	const defautOptions = formsEntities[idform]?.options;

	// taile par defaut des options
	const defautOptionsLength = defautOptions?.length;

	// ajouter une option
	const addOption = useCallback(() => {
		dispatchQuestionRadioOrCheckbox(
			setQuestionForm({
				type: "ADDOPTIONS",
				newOption: {
					id: nanoid(),
					label: `Option ${defautOptionsLength + 1}`,
					prix: `Option ${defautOptionsLength + 1}`,
				},
				id: idform,
			})
		);
	}, [idform, dispatchQuestionRadioOrCheckbox, defautOptionsLength]);

	// supprimer une option
	const deleteOption = useCallback(
		(id) => {
			dispatchQuestionRadioOrCheckbox(
				setQuestionForm({
					type: "DELETEOPTIONS",
					idOption: id,
					id: idform,
				})
			);
		},
		[dispatchQuestionRadioOrCheckbox, idform]
	);

	// changer le prix d'une ption
	const optionPriceHandleChange = useCallback(
		(e, id, option, index) => {
			const type = e.target.type;
			const value = e.target.value;

			let newOption;

			switch (type) {
				case "text":
					newOption = {
						...option,
						label: value,
					};
					console.log(newOption);
					dispatchQuestionRadioOrCheckbox(
						setQuestionForm({
							type: "OPTIONSLABEL",
							idOption: id,
							id: idform,
							indexOption: index,
							option: newOption,
						})
					);
					break;
				case "number":
					newOption = {
						...option,
						prix: value,
					};
					dispatchQuestionRadioOrCheckbox(
						setQuestionForm({
							type: "OPTIONSPRIX",
							idOption: id,
							id: idform,
							indexOption: index,
							option: newOption,
						})
					);
					break;

				default:
					break;
			}
		},
		[idform, dispatchQuestionRadioOrCheckbox]
	);

	return (
		<div>
			<div className="sm:space-y-4 space-y-8">
				{defautOptions?.map((option, index) => {
					return (
						<div
							className="flex sm:items-center items-start group gap-y-4 sm:gap-x-4 flex-col sm:flex-row "
							key={option.id}>
							<div className="flex items-center group grow">
								{/* icon d'identification du type de champs d'entree ( radio ou checkbox ) */}
								<Icon className="sm:h-6 sm:w-6 h-4 w-4  text-gray-500 " />

								{/* label option */}
								<label
									htmlFor={`${index}-${option.label}`}
									className="md:grow">
									{/*champs de saisi de l'option  */}
									<input
										type="text"
										onChange={(e) =>
											optionPriceHandleChange(
												e,
												option.id,
												option,
												index
											)
										}
										defaultValue={option.label}
										name={option.label}
										id={`${index}-${option.label}`}
										className="block w-full border-0 focus:border-0 focus:border-b-2  focus:border-secondary-600 focus:ring-0 sm:text-md hover:border-gray-400 hover:border-0 hover:border-b"
										placeholder={`${option.label}`}
									/>
								</label>
							</div>

							<div className="flex items-center">
								<label
									htmlFor={`${index}-${option.prix}`}
									className="sm:pl-8 ">
									{/*champs de saisi du prix  */}
									<input
										onChange={(e) =>
											optionPriceHandleChange(
												e,
												option.id,
												option,
												index
											)
										}
										type="number"
										name={option.prix}
										id={`${index}-${option.prix}`}
										min={0}
										defaultValue={option.prix}
										className="block w-full border-0 focus:border-b bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md text-gray-700 h-8 sm:h-10"
										placeholder={`${option.prix}`}
									/>
								</label>

								{/* affiche le button supprimer uniquement si le nombre d'option est superieur à 0 */}
								{index !== 0 || defautOptionsLength > 1 ? (
									<XMarkIcon
										className="h-6 w-6  ml-4 sm:ml-0 hover:text-gray-700 text-gray-500 cursor-pointer"
										onClick={() => deleteOption(option.id)}
									/>
								) : null}
							</div>
						</div>
					);
				})}

				{/* ajouter une nouvelle option  */}
				<div className="flex items-center gap-x-3 w-full">
					<Icon className="sm:h-6 sm:w-6 h-4 w-4 text-gray-500 " />
					<button
						type="button"
						onClick={addOption}
						className="border-0 outline-one text-gray-500 cursor-text text-sm  hover:border-gray-400 hover:border-0 hover:border-b">
						{" "}
						Ajouter une option
					</button>
				</div>
			</div>
		</div>
	);
};

export default RadioQuestion;
