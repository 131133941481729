/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description user reglement page
 * @component ReglementPage
 */
import { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Alert } from "../../components/feedback/ModalWithoutAction";

import axiosInstance from "../../apis/inchAssur";

import { getToken } from "../../features/user/auth.slice";
import { getOperation } from "../../features/user/payement.slice";
import { useGetReglementsUserQuery } from "../../features/api/apiSlice";

import Spinner from "../../UI/spinner/Spinner";
import Pagination from "../../UI/pagination";

import usePagination from "../../hooks/usePagination";

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

const ReglementPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	// location information
	const { hash, key, search } = location;

	// query parameter
	const query = new URLSearchParams(search);

	const status = query.get("status");
	const transaction_id = query.get("transaction_id");
	const payment_ref = query.get("payment_ref");
	const item_ref = query.get("item_ref");

	// operation
	const operation = useSelector(getOperation);

	// token
	const token = useSelector(getToken);

	// alert state
	const [alert, setAlert] = useState({
		isOpen: false,
		text: "",
	});

	// scroll to element
	useEffect(() => {
		if (hash) {
			const targetElement = document.getElementById(hash.substring(1));
			targetElement?.scrollIntoView({ behavior: "smooth" });
		}
	}, [key, hash]);

	const statusClassName = (status) => {
		switch (status) {
			case "Annulé":
				return "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800 whitespace-nowrap my-2";
			case "Echoué":
				return "inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800 whitespace-nowrap my-2";
			case "Validé":
				return "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 whitespace-nowrap my-2 ";

			default:
				break;
		}
	};

	// close alert
	const closeAlert = useCallback(() => {
		setAlert((prevState) => {
			return {
				...prevState,
				isOpen: false,
				text: "",
			};
		});
		navigate("/operations/reglements", { replace: true, relative: false });
	}, [navigate]);

	// close alert
	useLayoutEffect(() => {
		if (status) {
			setTimeout(closeAlert, 10000);
		}

		return () => {
			clearTimeout();
		};
	}, [status, closeAlert]);

	useEffect(() => {
		if (status) {
			axiosInstance
				.get(
					`/paiement/status?status=${status}&code=${transaction_id}&operation=${operation}&payment_ref=${payment_ref}&item_ref=${item_ref}`,
					{
						headers: {
							authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					setAlert((prevState) => {
						return {
							...prevState,
							isOpen: true,
							text: res.data.message,
							error: status,
						};
					});
					navigate("/operations/reglements", {
						replace: true,
						relative: false,
					});
				})
				.catch((err) => {});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);
	const [selectedReglement, setSelectedReglement] = useState(null);
	// get reglement request
	const {
		data: reglements,
		isSuccess,
		isLoading,
	} = useGetReglementsUserQuery();

	//data tabele
	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS}
	});
    const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
	
	const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
	const Daterendez =(rowData)=>{
		return (
		   <div>
			   <span> {rowData.date} {rowData.heure}</span>
		   </div>
		)
   }
	const statusItemTemplate = (rowData) => {
        return  (<div className={statusClassName(rowData.statut)}>
			          {rowData.statut}
			    </div>
    )};
	const itemsPerPage = 10;

	// paginition hook
	const { next, prev, jump, currentData, currentPage, maxPage, dataLength } =
		usePagination(reglements?.data, itemsPerPage);

	let content;
	const header = renderHeader();
	if (isLoading) {
		content = (
			<div className="flex justify-center">
				{" "}
				<Spinner className="w-10 h-10 text-primary-700" />
			</div>
		);
	} else if (isSuccess) {
		reglements?.data?.length === 0
			? (content = (
					<p className="text-center texr-gray-400 text-2xl  ">
						{" "}
						Aucun réglement
					</p>
			  ))
			: (content = (
					<>
					<DataTable value={reglements?.data} paginator showGridlines  selectionreglement="checkbox" selection={selectedReglement} onSelectionChange={(e) => setSelectedReglement(e.value)} rows={10} 
							filters={filters} globalFilterFields={['nom','statut']} header={header}
							emptyMessage="No customers found.">
							<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
						<Column   header="reglementID" field="id"  style={{ minWidth: '12rem' }}  />
						<Column header="Transaction" field="transactionID" style={{ minWidth: '10rem' }}   /> 
						<Column header="Operateur" sortable  field="operateur" style={{ minWidth: '10rem' }}   /> 
						<Column   header="Montant (XAF)"  style={{ minWidth: '12rem' }} field="montant" />
					    <Column field="duree"  style={{ minWidth: '12rem' }} body={Daterendez} />
						<Column header="Statut"sortable   style={{ minWidth: '12rem' }} body={statusItemTemplate}  />
						</DataTable> 
						
						{/* pagination  */}
						{reglements?.data?.length > itemsPerPage && (
							<Pagination
								next={next}
								prev={prev}
								jump={jump}
								currentData={currentData}
								currentPage={currentPage}
								maxPage={maxPage}
								dataLength={dataLength}
								itemsPerPage={itemsPerPage}
							/>
						)}
					</>
			  ));
	}

	return (
		<>
			{alert.isOpen && (
				<div className="p-8">
					{" "}
					<Alert
						message={alert.text}
						error={alert.error}
						onClick={closeAlert}
					/>
				</div>
			)}

			<div className="px-4 sm:px-6 lg:px-8 mt-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-xl font-bold text-primary-900">
							Réglements
						</h1>
						<p className="mt-2 text-sm text-gray-700">
							La liste des réglements.
						</p>
					</div>
				</div>
				{content}
			</div>
		</>
	);
};

export default ReglementPage;
