// config file
export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_API_CHAT_BASE_URL = process.env.REACT_APP_API_CHAT_BASE_URL;

// user profile  config
export const USERCODE = {
  ADMIN: process.env.REACT_APP_ADMIN,
  INSURER: process.env.REACT_APP_INSURER,
  DOCTOR: process.env.REACT_APP_DOCTOR,
  FAMILY: process.env.REACT_APP_FAMILY,
  ENTREPRISE: process.env.REACT_APP_ENTREPRISE,
  CLIENT: process.env.REACT_APP_CLIENT,
};
