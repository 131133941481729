/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description  list chats
 * @component Chats
 */
import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

import StartChatButton from "./StartChatButton";
import SimpleChats from "./SimpleChats";
import IncidentChats from "./IncidentChats";
import SinistreChats from "./SinistreChats";
import GroupeChat from "./GroupeChat";

import { classNames } from "../../../../styles";

import { useGetChatsQuery } from "../../../../features/api/apiSlice";
import {
  getSelectedChat,
  selectChat,
} from "../../../../features/chats/chats.slice";

const Chats = ({
  setopenIncidentModal,
  setopenSinistreModal,
  setopenNewChat,
  setopenNewChatGroup,
}) => {
  const dispatch = useDispatch();
  // incident state
  const [incidentChats, setincidentChats] = useState([]);
  // simple chat state
  const [messageChats, setmessageChats] = useState([]);
  // sinistre state
  const [sinistreChats, setsinistreChats] = useState([]);
  // chat group state
  const [groupeChats, setgroupeChats] = useState([]);

  // get chat request
  const {
    data: chats,
    isLoading: chatsIsLoading,
    isSuccess: chatsIsSuccess,
  } = useGetChatsQuery();

  // select chat
  const selectChatHandleClick = (chat) => {
    dispatch(selectChat(chat));
  };

  // get selected chat
  const selectedChat = useSelector(getSelectedChat);

  // set chat state
  useEffect(() => {
    if (chatsIsSuccess) {
      setmessageChats(chats.data.filter((item) => item.type === "Message"));
      setincidentChats(chats.data.filter((item) => item.type === "Incident"));
      setsinistreChats(chats.data.filter((item) => item.type === "Sinistre"));
      setgroupeChats(chats.data.filter((item) => item.type === "Groupe"));
    }

    return () => {};
  }, [chatsIsSuccess, chats]);

  return (
    <div
      className={classNames(
        selectedChat ? " hidden md:block" : "flex",
        "flex flex-col w-full xl:w-2/6 md:w-2/5 border-r space-y-2 max-h-full "
      )}
    >
      <div className="flex items-center justify-between border-b px-4 py-4 box-border">
        <h1 className="text-lg text-gray-800">Messages</h1>
        <div className="flex items-center gap-x-2">
          {/* search button  */}
          <span className="hover:bg-gray-100 p-2 rounded-full w-10 h-10">
            <MagnifyingGlassIcon className=" text-gray-700" />
          </span>
          {/* start chat button  */}
          <StartChatButton
            setopenIncidentModal={setopenIncidentModal}
            setopenSinistreModal={setopenSinistreModal}
            setopenNewChatGroup={setopenNewChatGroup}
            setopenNewChat={setopenNewChat}
          />
        </div>
      </div>

      <div className=" px-2 sm:px-3 space-y-3 flex-1 overflow-y-auto">
        {/* chat loading  */}
        {chatsIsLoading &&
          [1, 2, 3].map((item, index) => (
            <div
              key={index}
              className=" animate-pulse flex gap-x-2 items-center"
            >
              <span className=" h-10 w-10 bg-slate-300 rounded-full"></span>
              <span className=" w-4/5 flex flex-col gap-y-2 ">
                <span className="bg-slate-300 h-2 rounded-md w-2/5"></span>
                <span className="bg-slate-300 h-2 rounded-md w-4/5"></span>
              </span>
            </div>
          ))}
        {chatsIsSuccess && chats.data.length === 0 ? (
          <p className="text-lg text-gray-600 text-center py-2">
            {" "}
            Aucune Echange
          </p>
        ) : (
          <>
            {/* chat group */}
            {groupeChats.length > 0 && (
              <GroupeChat
                listChat={groupeChats}
                selectChat={selectChatHandleClick}
              />
            )}
            {/* message chat  */}
            {messageChats.length > 0 && (
              <SimpleChats
                listChat={messageChats}
                selectChat={selectChatHandleClick}
              />
            )}
            {/* incident chat  */}
            {incidentChats.length > 0 && (
              <IncidentChats
                listChatIncident={incidentChats}
                selectChat={selectChatHandleClick}
              />
            )}
            {/* sinistre chat  */}
            {sinistreChats.length > 0 && (
              <SinistreChats
                listChatSinistre={sinistreChats}
                selectChat={selectChatHandleClick}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Chats;
