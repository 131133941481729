/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description selected question type
 * @component SelectLabel
 */
import React, { useCallback } from "react";
import {
	MdOutlineRadioButtonUnchecked,
	MdCheckBoxOutlineBlank,
} from "../../../../../UI/svg";
import SelectMenu from "../../../../../UI/form/SelectMenu";
import InputTextQuestion from "../../../../../UI/form/InputTextQuestion";
import RadioOrCheckboxQuestion from "../../../../../UI/form/RadioOrCheckboxQuestion";
import { setQuestionForm } from "../../../../../features/form/form.slice";
import { useDispatch } from "react-redux";

const SelectLabel = (props) => {
	const dispatchForm = useDispatch();

	// props
	const { idform, formsEntities } = props;

	// question default type
	const defautType = formsEntities[idform]?.type;

	// question default name
	const defaultValueName = formsEntities[idform]?.name;

	let content;

	switch (defautType) {
		case "text":
			content = (
				<InputTextQuestion
					title="Réponse courte"
					idform={idform}
					formsEntities={formsEntities}
				/>
			);
			break;
		case "textArea":
			content = (
				<InputTextQuestion
					title="Réponse longue"
					idform={idform}
					formsEntities={formsEntities}
				/>
			);
			break;
		case "checkbox":
			content = (
				<RadioOrCheckboxQuestion
					Icon={MdCheckBoxOutlineBlank}
					idform={idform}
					formsEntities={formsEntities}
				/>
			);
			break;
		case "radio":
			content = (
				<RadioOrCheckboxQuestion
					Icon={MdOutlineRadioButtonUnchecked}
					idform={idform}
					formsEntities={formsEntities}
				/>
			);
			break;

		default:
			break;
	}

	// set name question
	const setQuestionNameHandler = useCallback(
		(e, id) => {
			const name = e.target.value;

			dispatchForm(setQuestionForm({ type: "NAME", name, id }));
		},
		[dispatchForm]
	);

	return (
		<div className=" grid grid-cols-3 gap-4 pb-8 sm:pb-14">
			{/* input name  */}
			<div className="col-span-3 sm:col-span-2">
				<label
					htmlFor="name"
					className="block text-sm font-medium text-gray-700"></label>
				<div className="mt-1 border-b border-gray-300 focus-within:border-secondary-600">
					<input
						defaultValue={defaultValueName}
						type="text"
						name="name"
						id="name"
						className="block w-full border-0 border-b border-gray-500 bg-gray-50 focus:border-secondary-600 focus:ring-0 sm:text-md h-14"
						placeholder="Question"
						onChange={(e) => setQuestionNameHandler(e, idform)}
					/>
				</div>
			</div>

			{/* select question type  */}
			<div className="col-span-3 sm:col-span-1 mt-2 sm:mt-0">
				<SelectMenu
					idform={idform}
					formsEntities={formsEntities}
				/>
			</div>

			{/* content  */}
			<div className="col-span-3">{content}</div>
		</div>
	);
};

export default SelectLabel;
